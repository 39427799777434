import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const BsModal = ({ id, children, size }) => {
    //const ID = React.useMemo(() => id || uuidv4(), [id]);

    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog ${size ? "modal-" + size : ""} modal-dialog-centered`}>
                <div className="modal-content rounded-0">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default BsModal;
