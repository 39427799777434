import React, {useEffect, useState} from 'react';
import Title from '../components/Title';
import HashtagButton from '../components/HashtagButton';
import UserIcon from '../assets/images/empty-user.svg';
import CommunityIcon from '../assets/images/community.svg';
import lastMonthIcon from '../assets/images/acceleration.svg'
import useContextApp from "../hooks/useContextApp";
import moment from "moment";
import {API_TAG_AROUND, API_TERRITORY_STATS} from "../utils/api";
import useRequest from "../hooks/useRequest";
import Export from "../components/Export";
import ShareModal from "../components/ShareModal";

export default function Dashboard(props) {
    const [auth] = useContextApp();
    const [handleRequest] = useRequest();

    console.log("auth", auth)
    const [tagsAround, setTagsAround] = useState([]);
    const [requestedTagsAround, setRequestedTagsAround] = useState(false);
    const [requestingTagsAround, setRequestingTagsAround] = useState(false);

    const [territoryStats, setTerritoryStats] = useState(null);
    const [requestedTerritoryStats, setRequestedTerritoryStats] = useState(false);
    const [requestingTerritoryStats, setRequestingTerritoryStats] = useState(false);

    useEffect(() => {
        if (!requestedTagsAround && !requestingTagsAround) {
            setRequestingTagsAround(true);
            handleRequest("get", API_TAG_AROUND + "?latitude="+auth.user.profile?.commune?.latitude+"&longitude="+auth.user.profile?.commune?.longitude, {},
                auth, (data, status) => {
                    if (status === "Done") {
                        //console.log('data tags around', data);
                        setTagsAround(data);
                        setRequestingTagsAround(false);
                        setRequestedTagsAround(true);
                    } else if (status === "Error") {
                        //console.error(data);
                    }
                });
        }
    }, [requestedTagsAround])

    useEffect(() => {
        if (!requestedTerritoryStats && !requestingTerritoryStats) {
            setRequestingTerritoryStats(true);
            handleRequest("get", API_TERRITORY_STATS, {},
                auth, (data, status) => {
                    if (status === "Done") {
                        console.log("territoryStats", data);
                        setTerritoryStats(data);
                        setRequestingTerritoryStats(false);
                        setRequestedTerritoryStats(true);
                    } else if (status === "Error") {
                        //console.error(data);
                    }
                });
        }
    }, [requestedTagsAround])

    const firstConnexionDate = moment(auth.otherDetails?.firstConnexionDate);

    return (
        <>
            <div className="container">
                <div className="mb-5">
                    <div className="d-flex align-items-center">
                        <img src={lastMonthIcon} alt="30-last-days" className="icon-lg me-3"/>
                        <p className="mb-0">Au cours des 30 derniers jours :</p>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-12 col-md-6 col-xl-4">
                        <Title title="MOI" icon={UserIcon} large/>
                        <div className="mt-5">
                            <p><strong>Mes données</strong></p>
                            <p>Inscrit.e depuis le {firstConnexionDate.format("DD/MM/YYYY")}</p>
                            <p>Trajets effectués : {auth.otherDetails?.nbTrajets}</p>
                            <p className="mb-5">CO2 économisé : {parseFloat(auth.otherDetails?.co2).toFixed(2)} Kg</p>
                            <p className="mb-4">Nombre de personnes parrainées : {auth.otherDetails?.nbSponsorChild}</p>
                            <div className="text-center">
                                <button className="btn btn-primary" onClick={() => props.onShare()}>
                                    <strong>Partager mes résultats</strong>
                                </button>
                            </div>
                        </div>

                        <div>
                            <Export />
                        </div>

                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <Title title="MA COMMUNAUTÉ" icon={CommunityIcon} large/>
                        {territoryStats !== null &&
                            <div className="mt-5">
                                <p><strong>{territoryStats.territory?.nom}</strong></p>
                                <p>Inscrit.es : {territoryStats.nbUsers}</p>
                                <p>Trajets effectués : {territoryStats.nbTrajets}</p>
                                <p className="mb-4">CO2 économisé : {parseFloat(territoryStats.co2).toFixed(2)} Kg</p>
                                <p><strong>Mes abonnements</strong></p>
                                <div>
                                    {console.log('aub', auth.otherDetails.subscription)}
                                    {auth.otherDetails.subscription === null ?
                                      (
                                        <p>Le territoire auquel votre commune est rattachée n'a pas souscrit à cette fonctionnalité.</p>
                                      ) :
                                      (
                                        <ul className="list-unstyled pe-xl-5 mb-4">
                                            {auth.user.tags?.map((tag, index) => {
                                                return (<li><HashtagButton key={index} title={tag.name} link={"/account/hashtag/"+tag.id}/></li>);
                                            })}
                                        </ul>
                                      )}
                                </div>

                                <p><strong>Mes suggestions de hashtags</strong></p>
                                <div>
                                    {auth.user.subscription === null ?
                                      (
                                        <p>Le territoire auquel votre commune est rattachée n'a pas souscrit à cette fonctionnalité.</p>
                                      ) :
                                      (
                                        <ul className="list-unstyled pe-xl-5">
                                            {tagsAround.map((tag, index) => {
                                                return (<li><HashtagButton key={index} title={tag.name} link={"/account/hashtag/"+tag.id}/></li>);
                                            })}
                                        </ul>
                                      )}
                                </div>
                            </div>
                        }
                    </div>
                    {/*<div className="col-12 col-md-6 col-xl-4">
                        <Title title="&nbsp;" large icon={FixIcon}/>
                        <div className="mt-5">
                            <p><strong>[Nom territoire]</strong></p>
                            <p>Inscrit.es : [Nb inscrit.es]</p>
                            <p>Trajets effectués : [Nb trajets effectués]</p>
                            <p className="mb-4">CO2 économisé : [Nb CO2]</p>
                            <p><strong>Mes abonnements</strong></p>
                            <ul className="list-unstyled pe-xl-5 mb-4">
                                <li><HashtagButton title="#Test" link="/"/></li>
                                <li><HashtagButton title="#Test" link="/"/></li>
                                <li><HashtagButton title="#Test" link="/"/></li>
                            </ul>
                            <p><strong>Mes suggestions de hashtags</strong></p>
                            <ul className="list-unstyled pe-xl-5">
                                <li><HashtagButton title="#Test" link="/"/></li>
                                <li><HashtagButton title="#Test" link="/"/></li>
                                <li><HashtagButton title="#Test" link="/"/></li>
                            </ul>
                        </div>
                    </div>*/}
                </div>
            </div>
        </>
    );

}
