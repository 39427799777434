import React from 'react';

import RezoPouceLogo from '../assets/images/logo-rezo-pouce.svg';
import useContextApp from "../hooks/useContextApp";
import {Link} from "react-router-dom";

export default function Navbar(props) {

    const [auth] = useContextApp();

    return(
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid container-lg">
                    <a className="navbar-brand" href="/">
                        <img src={RezoPouceLogo} alt="Logo de Rezo Pouce" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item align-items-center d-flex">
                                <a className="nav-link" target="_blank" rel="noreferrer" href="https://www.rezopouce.fr/page/Qui_sommes_nous">QUI SOMMES-NOUS ?</a>
                            </li>
                            <li className="nav-item align-items-center d-flex">
                                <a className="nav-link" target="_blank" rel="noreferrer" href="https://www.rezopouce.fr/page/Nos_valeurs">NOS VALEURS ?</a>
                            </li>
                            <li className="nav-item align-items-center d-flex">
                                <a className="nav-link" target="_blank" rel="noreferrer" href="https://www.rezopouce.fr/page/Confiance_et_serenite">CONFIANCE ET SÉRÉNITÉ</a>
                            </li>
                            <li className="nav-item align-items-center d-flex">
                                <a className="nav-link" target="_blank" rel="noreferrer" href="https://www.rezopouce.fr/page/L_autostop_avec_Rezo_Pouce">L'AUTOSTOP AVEC REZO POUCE</a>
                            </li>
                            <li className="nav-item align-items-center d-flex">
                                {auth.connected
                                    ?
                                        <div>
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-primary d-block mb-1 dropdown-toggle btn-pen" type="button" id="dropdown-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                                    MON COMPTE
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end rounded-0" aria-labelledby="dropdown-menu">
                                                    <li><a className="dropdown-item" href="https://rezopouce.fr/moncompte/">Mon profil</a></li>
                                                    <li><a className="dropdown-item" href="https://rezopouce.fr/moncompte/autorisations">Mes autorisations (RGPD)</a></li>
                                                </ul>
                                            </div>
                                            <Link className="btn btn-sm btn-secondary d-block btn-locker" to="/logout">DÉCONNEXION</Link>
                                        </div>
                                    :
                                    <div>
                                        <Link className="btn btn-sm btn-primary d-block btn-locker" to="/login">CONNEXION</Link>
                                    </div>
                                }

                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    );

}
