import React from 'react';

import RateLogo from '../assets/images/thumb.svg';
import EmptyUserLogo from '../assets/images/empty-user.svg';
import useContextApp from "../hooks/useContextApp";
import {baseUrlAvatar} from "../utils/api";
import {getProfileLevel} from "../utils/tools";

export default function Header(props) {
    const [auth] = useContextApp();

    let avatar = EmptyUserLogo;
    if (auth?.user?.profile?.photoIdentite && auth?.user?.profile?.photoIdentite?.fullFilename) {
        avatar = baseUrlAvatar + auth.user.profile.photoIdentite.fullFilename;
    }

    return(
        <header>
            <div className="bg-primary-dark">
                <div className="container-fluid container-lg py-5">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <img src={avatar} alt="user-avatar" className="user-avatar"/>
                        </div>
                        {auth.user !== null && auth.otherDetails !== null &&
                            <div className="col">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-auto">
                                        <h1 className="h2 title mb-0">Bienvenue {auth.user.prenom} !</h1>
                                    </div>
                                    <div className="col-auto col-md-auto">
                                        <Rating rating={auth.user.profile?.rating}/>
                                    </div>
                                    <div className="col-auto col-md-auto ps-0">
                                        {getProfileLevel(auth.otherDetails.nbTrajets)}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
}

function Rating(props) {
    const rating = Math.round(props.rating);
    var rates = [];
    for (var i = 0; i < rating; i++) {
        rates.push(<img className="thumbs me-1" alt={"thumbs-full-"+i} src={RateLogo} key={i} />);
    }
    if (rating < 5) {
        for (var j = rating; j < 5; j++) {
            rates.push(<img className="thumbs empty me-1" alt={"thumbs-empty-"+j} src={RateLogo} key={j} />);
        }
    }
    return rates;
}
