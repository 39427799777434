import React from 'react';
import { Checkbox } from '../../../../components/form/checkbox';
import { Date, Time } from '../../../../components/form/datetime';

const Recurent = (props) => {
    const validateFormCheckbox = (value, checkboxName) => {
        if (props.values(checkboxName) && !props.values(checkboxName + "T")) {
            return false;
        }
        return true;
    }

    return (
        <div className="container">
            <div className="row px-4">
                <div className="col-12 col-lg-6 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Lundi</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Aller" name="mo0" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="mo0T" ref={props.form({validate: (value) => validateFormCheckbox(value, "mo0")})} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Retour" name="mo1" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="mo1T" ref={props.form({validate: (value) => validateFormCheckbox(value, "mo1")})} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Mardi</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Aller" name="tu0" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="tu0T" ref={props.form({validate: (value) => validateFormCheckbox(value, "tu0")})} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Retour" name="tu1" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="tu1T" ref={props.form({validate: (value) => validateFormCheckbox(value, "tu1")})} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Mercredi</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Aller" name="we0" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="we0T" ref={props.form({validate: (value) => validateFormCheckbox(value, "we0")})} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Retour" name="we1" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="we1T" ref={props.form({validate: (value) => validateFormCheckbox(value, "we1")})} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Jeudi</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Aller" name="th0" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="th0T" ref={props.form({validate: (value) => validateFormCheckbox(value, "th0")})} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Retour" name="th1" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="th1T" ref={props.form({validate: (value) => validateFormCheckbox(value, "th1")})} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Vendredi</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Aller" name="fr0" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="fr0T" ref={props.form({validate: (value) => validateFormCheckbox(value, "fr0")})} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Retour" name="fr1" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="fr1T" ref={props.form({validate: (value) => validateFormCheckbox(value, "fr1")})} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Samedi</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Aller" name="sa0" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="sa0T" ref={props.form({validate: (value) => validateFormCheckbox(value, "sa0")})} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Retour" name="sa1" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="sa1T" ref={props.form({validate: (value) => validateFormCheckbox(value, "sa1")})} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Dimanche</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Aller" name="su0" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="su0T" ref={props.form({validate: (value) => validateFormCheckbox(value, "su0")})} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Checkbox title="Retour" name="su1" ref={props.form()} />
                        <Time errors={props.errors} placeholder="00:00" name="su1T" ref={props.form({validate: (value) => validateFormCheckbox(value, "su1")})} />
                    </div>
                </div>
                <div className="col-12 row">
                    <div className="col-12">
                        <p className="text-primary" style={{fontWeight: 700}}>Période</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <Date errors={props.errors} label="À partir du" placeholder="00/00/0000" name="startAt" ref={props.form()} />
                    </div>
                    <div className="col-12 col-md-6">
                        <Date errors={props.errors} label="Jusqu'au" placeholder="00/00/0000" name="endAt" ref={props.form()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recurent;
