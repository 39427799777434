import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import AccountApp from "./AccountApp";
import PrivateRoutes from "./utils/private-routes";
import RedirectApp from "./RedirectApp";
import LoginFromWeb from "./pages/LoginFromWeb";

export default function App() {
  return (
    <>
      <Router>
        <Navbar />

        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout}/>
          <Route path="/loginfromweb" component={LoginFromWeb} />
          <PrivateRoutes path="/account" component={AccountApp}/>
          <PrivateRoutes path="/" component={RedirectApp}/>
        </Switch>
      </Router>
    </>
  );
}
