import React, {useEffect, useState} from 'react';
import * as bootstrap from "bootstrap";
import FromToIcon from '../assets/images/trip-card/from-to.svg';
import EuroIcon from '../assets/images/trip-card/euro.svg';
import DateTimeIcon from '../assets/images/trip-card/hours.svg';
import ConductorIcon from '../assets/images/trip-card/conductor.svg';
import EmptyUserLogo from "../assets/images/empty-user.svg";
import {API_PROGRAMMED_ASK, API_PROGRAMMED_USERTAGS, baseUrlApi, baseUrlAvatar} from "../utils/api";
import {
    capitalizeFirstLetter,
    getTripDestinationCity,
    getTripDestinationDescription,
    getTripOriginCity,
    getTripOriginDescription
} from "../utils/tools";
import moment from "moment";
import 'moment/locale/fr';
import useRequest from "../hooks/useRequest";
import useContextApp from "../hooks/useContextApp";
import {useSnackbar} from "react-simple-snackbar";
import {Link, useHistory} from "react-router-dom";

moment.updateLocale('fr', {
    weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
});

moment.locale('fr', {
    calendar: {
        lastDay: '[Hier à] LT',
        sameDay: "[Aujourd'hui à] LT",
        nextDay: '[Demain à] LT',
        lastWeek: '[last] ddd [à] LT',
        nextWeek: 'ddd L [à] LT',
        sameElse: 'ddd L [à] LT',
    },
});

export default function TripCard(props) {
    const [handleRequest] = useRequest();
    const [auth] = useContextApp();
    const [openSnackbar] = useSnackbar()

    let history = useHistory();

    const [trip, setTrip] = useState(props.data);

    let avatar = EmptyUserLogo;
    if (trip.user.profile.photoIdentite && trip.user.profile.photoIdentite?.fullFilename) {
        avatar = baseUrlAvatar + trip.user.profile.photoIdentite.fullFilename;
    }

    const originCity = getTripOriginCity(trip);
    const originDescription = getTripOriginDescription(trip);
    const destinationCity = getTripDestinationCity(trip);
    const destinationDescription = getTripDestinationDescription(trip);

    let description = "";
    if (props.isRecurrent) {
        description = trip.description === 'null' ? `Vous n'avez pas rentré d'informations complémentaires.` : trip.description ? trip.description : `Vous n'avez pas rentré d'informations complémentaires.`;
    } else {
        description = trip.description === 'null' ? `${trip.user.prenom} n'a pas rentré d'informations complémentaires.` : trip.description ? trip.description : `${trip.user.prenom} n'a pas rentré d'informations complémentaires.`;
    }

    const date = capitalizeFirstLetter(moment(trip.startAt).calendar());

    const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];
    const daysString = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

    const recurrenceDisable = (id) => {
        handleRequest("get", baseUrlApi + "/recurrence/"+id+"/disable", {},
            auth, (data, status) => {
                if (status === "Done") {
                    props.needRefresh();
                    openSnackbar(data.message);
                } else if (status === "Error") {
                    //console.error(data);
                }
            });
    }

    const recurrenceEnable = (id) => {
        handleRequest("get", baseUrlApi + "/recurrence/"+id+"/enable", {},
            auth, (data, status) => {
                if (status === "Done") {
                    props.needRefresh();
                    openSnackbar(data.message);
                } else if (status === "Error") {
                    //console.error(data);
                }
            });
    }

    const recurrenceEdit = () => {
        props.onEditRecurrence(trip);
    }

    const recurrenceDelete = (id) => {
        handleRequest("get", baseUrlApi + "/recurrence/"+id+"/delete", {},
            auth, (data, status) => {
                if (status === "Done") {
                    props.needRefresh();
                    openSnackbar(data.message);
                } else if (status === "Error") {
                    //console.error(data);
                }
            });
    }

    const sendAsk = (programmedId) => {
        handleRequest("post", API_PROGRAMMED_ASK, {
                programmedId
            },
            auth, (data, status) => {
                console.log(data);
                if (status === "Done") {
                    console.log("OK");
                    //props.needRefresh();
                    openSnackbar("Demande de partage de trajet envoyée");
                    history.push('/account/mes-trajets');
                } else if (status === "Error") {
                    //console.error(data);
                }
            });
    }

    return(
            <div className="card card-trip">
                <div className="card-body">
                    <div className="row flex-nowrap">
                        <div className="col">
                            <div className="trip-destination row g-0 align-items-center flex-nowrap mb-2">
                                <div className="col-auto">
                                    <div className="img-wrapper">
                                        <img src={FromToIcon} alt="from-to"/>
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="mb-0"><strong>{originCity}</strong> - {originDescription}</p>
                                    <p className="mb-0"><strong>{destinationCity}</strong> - {destinationDescription}</p>
                                </div>
                            </div>
                            <div className="trip-datetime row g-0 align-items-center flex-nowrap mb-2">
                                <div className="col-auto">
                                    <div className="img-wrapper">
                                        <img src={DateTimeIcon} alt="from-to"/>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {props.isRecurrent
                                        ?
                                        <p className="mb-0">Trajet régulier</p>
                                        :
                                        <p className="mb-0">{date}<br/>{trip.recurrence ? 'Trajet régulier' : ""}</p>
                                    }
                                </div>
                            </div>
                            {!props.isRecurrent &&
                            <div className="trip-user row g-0 align-items-center flex-nowrap mb-2">
                                <div className="col-auto">
                                    <div className="img-wrapper">
                                        <img src={ConductorIcon} alt="from-to"/>
                                    </div>
                                </div>
                                <div className="col d-flex">
                                    <p className="mb-0" style={{cursor: "pointer"}} onClick={() => props.onContact(trip.user)}>{trip.user.prenom}</p>
                                    {trip.tag !== null &&
                                        <p className={"mb-0 ms-2 text-secondary"}><Link className={"text-decoration-none text-secondary"} to={"/account/hashtag/"+trip.tag.id}>#{trip.tag.name}</Link></p>
                                    }
                                </div>
                            </div>
                            }
                            {!props.isRecurrent &&
                            <div className="trip-cost row g-0 align-items-center flex-nowrap mb-2">
                                <div className="col-auto">
                                    <div className="img-wrapper">
                                        <img src={EuroIcon} alt="from-to"/>
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="mb-0">Coût indicatif: {(trip.kmPrice * trip.km).toFixed(2)}€</p>
                                </div>
                            </div>
                            }
                            {props.isRecurrent &&
                            <div className={"mb-3"}>
                                {days.map((day, index) => {
                                    if (trip[day + '0'] || trip[day + '1']) {
                                        let date0T = null;
                                        let date1T = null;
                                        if (trip[day + '0T']) {
                                            date0T = moment(trip[day + '0T']);
                                        }
                                        if (trip[day + '1T']) {
                                            date1T = moment(trip[day + '1T']);
                                        }
                                        return (
                                            <div key={index} className={"d-flex justify-content-between mb-1"}>
                                                <div>
                                                    {(date0T !== null || date1T !== null) &&
                                                    <span>Le {daysString[index]} </span>
                                                    }
                                                    {date0T !== null &&
                                                    <span>à {date0T.format("HH:mm")}</span>
                                                    }
                                                </div>
                                                <div>
                                                    {date1T !== null &&
                                                    <span>Retour à {date1T.format("HH:mm")}</span>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    return (
                                        <div key={index}></div>
                                    )
                                })}
                            </div>
                            }
                            <div className="trip-description row align-items-center flex-nowrap mb-2">
                                <p>{description}</p>
                            </div>
                            {!props.isRecurrent && trip.user.id !== auth.user.id &&
                            <div className="trip-action text-center">
                                <button type={"button"} className="btn btn-primary" onClick={() => sendAsk(trip.id)}>C'est parti</button>
                            </div>
                            }
                        </div>
                        {props.isRecurrent &&
                            <div className={"ps-3 col-auto"}>
                                {trip.enabled
                                    ?
                                        <div>
                                            <button type={"button"} className={"d-block px-3 w-100 btn btn-primary my-1"} onClick={() => recurrenceDisable(trip.id)}>Dépublier</button>
                                            <button type={"button"} className={"d-block px-3 w-100 btn btn-primary my-1 disabled"} disabled={true}>Modifier</button>
                                            <button type={"button"} className={"d-block px-3 w-100 btn btn-primary my-1 disabled"} disabled={true}>Supprimer</button>
                                        </div>
                                    :
                                        <div>
                                            <button type={"button"} className={"d-block px-3 w-100 btn btn-secondary my-1"} onClick={() => recurrenceEnable(trip.id)}>Publier</button>
                                            <button type={"button"} className={"d-block px-3 w-100 btn btn-primary my-1"} onClick={() => recurrenceEdit()}>Modifier</button>
                                            <button type={"button"} className={"d-block px-3 w-100 btn btn-primary my-1"} onClick={() => recurrenceDelete(trip.id)}>Supprimer</button>
                                        </div>
                                }
                        </div>
                        }
                    </div>
                </div>
            </div>
    );

}
