import React from 'react';

export default function Title(props) {
    return (
        <div className="title-section d-flex align-items-center">
            {props?.icon && 
                <img src={props.icon} alt={"icon-section-" + props.title} className={props.large ? 'icon-lg' : ''}/>
            }
            <h3 className="text mb-0">{props.title}</h3>
        </div>
    );
}