import React from 'react';
import Input from '../../../components/form/input/input';
import { Navigation } from '../Navigation';

const Last = (props) => {
    return (
        <div>
            <div className="container">
                <div className="px-4">
                    <Input label="Hashtag" disabled={props.subscription === null} placeholder="Hashtag (optionnel)" name="hastags" ref={props.form()} />
                  {props.subscription === null && (
                    <p class="small">Le territoire auquel votre commune est rattachée n'a pas souscrit à cette fonctionnalité.</p>
                  )}
                    <>
                        <label>Précisions</label>
                        <textarea placeholder="Précisions (optionnel)" name="precisions" className="form-control" ref={props.form()} />
                    </>
                </div>
            </div>
            <Navigation {...props} />
        </div>
    );
};

export default Last;
