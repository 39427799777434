import React from 'react';

export default function TextHighlightIcon(props) {
    return (
        <div className="hashtag-btn mb-2 d-flex justify-content-between">
            <div>{props.title}</div>
            <img src={props.icon}/>
        </div>
    );

}
