import React, {useEffect, useState} from 'react';
import * as bootstrap from "bootstrap";
import FromToIcon from '../assets/images/trip-card/from-to.svg';
import EuroIcon from '../assets/images/trip-card/euro.svg';
import DateTimeIcon from '../assets/images/trip-card/hours.svg';
import ConductorIcon from '../assets/images/trip-card/conductor.svg';
import EmptyUserLogo from "../assets/images/empty-user.svg";
import {API_PROGRAMMED_ASK, API_PROGRAMMED_USERTAGS, baseUrlApi, baseUrlAvatar} from "../utils/api";
import {
  capitalizeFirstLetter, getRdexDate,
  getRdexDestinationCity,
  getRdexDestinationDescription,
  getRdexOriginCity,
  getRdexOriginDescription,
} from "../utils/tools";
import moment from "moment";
import 'moment/locale/fr';
import useRequest from "../hooks/useRequest";
import useContextApp from "../hooks/useContextApp";
import {useSnackbar} from "react-simple-snackbar";
import {Link, useHistory} from "react-router-dom";

moment.updateLocale('fr', {
  weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
});

moment.locale('fr', {
  calendar: {
    lastDay: '[Hier à] LT',
    sameDay: "[Aujourd'hui à] LT",
    nextDay: '[Demain à] LT',
    lastWeek: '[last] ddd [à] LT',
    nextWeek: 'ddd L [à] LT',
    sameElse: 'ddd L [à] LT',
  },
});

export default function TripCard(props) {
  const [trip, setTrip] = useState(props.data);


  const originCity = getRdexOriginCity(trip);
  const originDescription = getRdexOriginDescription(trip);
  const destinationCity = getRdexDestinationCity(trip);
  const destinationDescription = getRdexDestinationDescription(trip);

  let description = "";
  description = trip.details === 'null' ? `${trip.driver?.alias} n'a pas rentré d'informations complémentaires.` : trip.details ? trip.details : `${trip.driver?.alias} n'a pas rentré d'informations complémentaires.`;

  const date = capitalizeFirstLetter(moment(getRdexDate(trip)).calendar());

  const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];
  const daysString = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

  return (
    <div className="card card-trip">
      <div className="card-body">
        <div className="row flex-nowrap">
          <div className="col">
            <div className="trip-destination row g-0 align-items-center flex-nowrap mb-2">
              <div className="col-auto">
                <div className="img-wrapper">
                  <img src={FromToIcon} alt="from-to"/>
                </div>
              </div>
              <div className="col">
                <p className="mb-0"><strong>{originCity}</strong> - {originDescription}</p>
                <p className="mb-0"><strong>{destinationCity}</strong> - {destinationDescription}</p>
              </div>
            </div>
            <div className="trip-datetime row g-0 align-items-center flex-nowrap mb-2">
              <div className="col-auto">
                <div className="img-wrapper">
                  <img src={DateTimeIcon} alt="from-to"/>
                </div>
              </div>
              <div className="col-auto">
                {trip.frequency !== 'punctual'
                  ?
                  <p className="mb-0">Trajet régulier</p>
                  :
                 <p className="mb-0">{date}<br/></p>
                }
              </div>
            </div>
            {trip.frequency === 'punctual' &&
            <div className="trip-user row g-0 align-items-center flex-nowrap mb-2">
              <div className="col-auto">
                <div className="img-wrapper">
                  <img src={ConductorIcon} alt="from-to"/>
                </div>
              </div>
              <div className="col d-flex">
                <p className="mb-0" style={{cursor: "pointer"}}
                   >{trip.driver?.alias}</p>
              </div>
            </div>
            }
            {trip.frequency === 'punctual' &&
            <div className="trip-cost row g-0 align-items-center flex-nowrap mb-2">
              <div className="col-auto">
                <div className="img-wrapper">
                  <img src={EuroIcon} alt="from-to"/>
                </div>
              </div>
              <div className="col">
                <p className="mb-0">Coût indicatif: {(trip.cost?.fixed ?? trip.cost.variable * trip.distance / 1000).toFixed(2)}€</p>
              </div>
            </div>
            }
            {trip.frequency !== 'punctual' &&
            <div className={"mb-3"}>
              {days.map((day, index) => {
                if (trip[day + '0'] || trip[day + '1']) {
                  let date0T = null;
                  let date1T = null;
                  if (trip[day + '0T']) {
                    date0T = moment(trip[day + '0T']);
                  }
                  if (trip[day + '1T']) {
                    date1T = moment(trip[day + '1T']);
                  }
                  return (
                    <div key={index} className={"d-flex justify-content-between mb-1"}>
                      <div>
                        {(date0T !== null || date1T !== null) &&
                        <span>Le {daysString[index]} </span>
                        }
                        {date0T !== null &&
                        <span>à {date0T.format("HH:mm")}</span>
                        }
                      </div>
                      <div>
                        {date1T !== null &&
                        <span>Retour à {date1T.format("HH:mm")}</span>
                        }
                      </div>
                    </div>
                  )
                }
                return (
                  <div key={index}></div>
                )
              })}
            </div>
            }
            <div className="trip-description row align-items-center flex-nowrap mb-2">
              <p>{description}</p>
            </div>
            <div className="trip-description row align-items-center text-center flex-nowrap mb-2">
              <p style={{color: '#e81478'}} class='fw-bold'>Annonce {props.operator.name}</p>
            </div>
            <div className="trip-action text-center">
              <button type={"button"} className="btn btn-primary" data-tip data-for="newTrajetButtonTooltip"
                      data-bs-toggle="modal" data-bs-target="#modalOtherProgrammedRequestAsk" onClick={() => props.onSend(trip, props.operator)}>C'est parti</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
