const prod = true;

export const baseUrl = prod ? 'https://api.rezopouce.fr' : 'http://127.0.0.1:8888/api/web';
export const baseUrlApi = prod ? 'https://api.rezopouce.fr/apimobile/v2' : 'http://127.0.0.1:8888/api/web/apimobile/v2';
export const baseUrlAvatar = prod ? 'https://api.rezopouce.fr/uploads/user/profile_photo/' : 'http://127.0.0.1:8888/api/web/uploads/user/profile_photo/';

// Auth

export const LOGIN_USER = baseUrl + "/loginapi2";

// Account

export const MY_ACCOUNT = baseUrlApi + "/user";

// Constants

export const API_CALENDAR = baseUrlApi + "/calendar";
export const API_TERRITORY_STATS = baseUrlApi + "/territorystats";
export const API_GET_RZP = baseUrlApi + "/getrzp";
export const API_TAG_AROUND = baseUrlApi + "/tag/around";
export const API_TAG_UNSUBSCRIBE = baseUrlApi + "/tag/unsubscribe";
export const API_TAG_CREATE_OR_SUBSCRIBE = baseUrlApi + "/tag/createorsubscribe";
export const API_PROGRAMMED_AROUND = baseUrlApi + "/programmed/around";
export const API_PROGRAMMED_AROUND_OTHER = baseUrlApi + "/programmed/around/other";
export const API_PROGRAMMED_USERTAGS = baseUrlApi + "/programmed/usertags";
export const API_PROGRAMMED_ME = baseUrlApi + "/programmed/me";
export const API_PROGRAMMED_TAG = baseUrlApi + "/programmed/tag";
export const API_PROGRAMMED_CREATE = baseUrlApi + "/programmed/create";
export const API_PROGRAMMED_EDIT = baseUrlApi + "/programmed/edit";
export const API_PROGRAMMED_ASK = baseUrlApi + "/programmed/ask";
export const API_PROGRAMMED_CANCEL = baseUrlApi + "/programmed/cancel";
export const API_PROGRAMMED_ASK_CANCEL = baseUrlApi + "/programmed/ask/cancel";
export const API_PROGRAMMED_ASK_ACCEPT = baseUrlApi + "/programmed/ask/accept";
export const API_PROGRAMMED_ASK_REFUSE = baseUrlApi + "/programmed/ask/refuse";
export const API_PROGRAMMED_TRAJET_CANCEL = baseUrlApi + "/programmed/trajet/cancel";
export const API_RECURRENCE_CREATE = baseUrlApi + "/recurrence/create";
export const API_EXPORT_TRAJETS = baseUrlApi + "/exporttrajets";
export const API_RDEX_JOURNEYS = baseUrl + "/rdexapi/journeys";
export const API_SEND_MESSAGE_RDEX = baseUrl + "/api/rdexapi/connections";
