import React from 'react';

const Button = ({ color, classNames, children, ...props }) => {
    return (
        <button className={`btn ${color ? "btn-" + color : ""} ${classNames || ""}`} {...props}>
            {children}
        </button>
    );
};

export default Button;