import React from 'react';
import { Navigation } from '../../Navigation';
import Recurent from './Recurent';
import Unique from './Unique';

const Second = (props) => {
    //console.log(props.values('routeType'));
    return (
        <div>
            {
                (props.values('routeType') === undefined || props.values('routeType') === "unique") ?
                <Unique {...props} />
                :
                <Recurent {...props} />
            }
            <Navigation {...props} />
        </div>
    );
};

export default Second;
