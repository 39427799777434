import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import StepWizard from 'react-step-wizard';
import First from './Steps/First';
import Last from './Steps/Last';
import Second from './Steps/Second';
import {API_CALENDAR, API_PROGRAMMED_CREATE, API_RECURRENCE_CREATE} from "../../utils/api";
import useRequest from "../../hooks/useRequest";
import useContextApp from "../../hooks/useContextApp";
import {useHistory, withRouter} from "react-router-dom";
import {useSnackbar} from "react-simple-snackbar";
import {Spinner} from "react-activity";
import "react-activity/dist/Spinner.css";

const CreateRoute = ({match, closeModal, history, google, currentStep, setCurrentStep, wizardID}) => {
  const [handleRequest] = useRequest();
  const [auth, {setForceUpdateCalendar, setForceUpdateRecurrences}] = useContextApp();
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const {register, handleSubmit, errors, getValues, trigger, setValue} = useForm()
  const [wizard, setWizard] = useState({
    transitions: {
      enterRight: "animated enterRight",
      enterLeft: "animated enterLeft",
      exitRight: "animated exitRight",
      exitLeft: "animated exitLeft",
    }
  })

  const [currentStepState, setCurrentStepState] = useState(currentStep)

  const setInstance = SW => setWizard(state => ({
    ...state,
    SW
  }));

  const [departureType, setDepartureType] = useState(2)

  const [pointDepart, setPointDepart] = useState(null);
  const [pointDestination, setPointDestination] = useState(null);

  const [departure, setDeparture] = useState(null);
  const [arrived, setArrived] = useState(null);

  const getCodeCommune = (address, lat, long) => {
    console.log('address', address, lat, long);
    return fetch(`https://api-adresse.data.gouv.fr/reverse/?lon=${long}&lat=${lat}`)
      .then(response => response.json())
      .then(responseJson => responseJson);
  };

  useEffect(() => {
    setCurrentStepState(currentStep);
  }, [currentStep])

  useEffect(async () => {
    if (pointDepart !== null) {
      await getCodeCommune(
        pointDepart.name,
        pointDepart.lat,
        pointDepart.lng
      ).then((res) => {
        console.log('get commune pointDepart', res);
        setDeparture({
          ...pointDepart,
          cityCode: res.features[0]?.properties?.citycode,
        });
        setDepartureType(2);
      });
    }
  }, [pointDepart]);

  useEffect(async () => {
    if (pointDestination !== null) {
      await getCodeCommune(
        pointDestination.name,
        pointDestination.lat,
        pointDestination.lng
      ).then((res) => {
        console.log('get commune pointDestination', res);
        setArrived({
          ...pointDestination,
          cityCode: res.features[0]?.properties?.citycode,
        })
      });
    }
  }, [pointDestination]);

  const onSubmit = async (values) => {
    console.log("Sent", values);
    setLoadingSubmit(true)

    if (values.routeType === undefined || values.routeType === "unique") {
      const dataToPost = {};
      dataToPost.typeOfProgrammedTrips = values.userType === "conductor" ? 2 : 1;
      dataToPost.description = values.precisions;
      dataToPost.hashtag = values.hastags;
      dataToPost.flexibility = values.flexibility;
      dataToPost.fullProfile = 1;
      dataToPost.dateDeparture = values.day_go + " " + values.hour_go + ":00";
      dataToPost.departureType = departureType;
      dataToPost.arrivedType = 2;

      dataToPost.haveTripsReturn = values.back;
      if (dataToPost.haveTripsReturn) {
        dataToPost.dateTripsReturn = values.day_back + " " + values.hour_back + ":00";
        dataToPost.descriptionTripsReturn = values.precisions;
      } else {
        dataToPost.dateTripsReturn = "";
        dataToPost.descriptionTripsReturn = "";
      }

      if (dataToPost.departureType === 2) {
        dataToPost.departureId = JSON.stringify(departure);
      } else {
        dataToPost.departureId = departure.id;
      }

      dataToPost.arrivedId = JSON.stringify(arrived);


      handleRequest("post", API_PROGRAMMED_CREATE, dataToPost,
        auth, (data, status) => {
          setLoadingSubmit(false);
          console.log("data", data, "status", status)
          let msg = data.text ? JSON.parse(data.text).message : data.message;
          openSnackbar(msg)

          if (status === "Done") {
            //console.log('data', data);
            if (data.success === true) {
              setForceUpdateRecurrences(true);
              setForceUpdateCalendar(true);
              closeModal();
              history.push('/account/mes-trajets');
              setTimeout(() => {
                history.go(0);
              }, 500);
            }
          } else if (status === "Error") {
            console.error("Error de requête")
          }
        });
    } else if (values.routeType === "recurrent") {
      const dataToPost = {};
      dataToPost.departureType = departureType;
      dataToPost.arrivedType = 2;

      if (dataToPost.departureType === 2) {
        dataToPost.departureId = JSON.stringify(departure);
      } else {
        dataToPost.departureId = departure.id;
      }

      dataToPost.arrivedId = JSON.stringify(arrived);
      dataToPost.description = values.precisions;
      dataToPost.hashtag = values.hastags;

      dataToPost.startDate = values.startAt;
      dataToPost.endDate = values.endAt;
      dataToPost.trips = [];

      const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];
      days.map((day, index) => {
        const trip = {};
        if (values[day + '0']) {
          //Aller activé
          trip.haveStartTrips = true;
          trip['hourStartTrips'] = values[day + '0T'];
        } else {
          //Aller désactivé
          trip.haveStartTrips = false;
          trip['hourStartTrips'] = null;
        }
        if (values[day + '1']) {
          //Retour activé
          trip.haveBackTrips = true;
          trip['hourBackTrips'] = values[day + '1T'];
        } else {
          //Retour désactivé
          trip.haveBackTrips = false;
          trip['hourBackTrips'] = null;
        }
        dataToPost.trips.push(trip);
      });

      dataToPost.trips = JSON.stringify(dataToPost.trips);

      handleRequest("post", API_RECURRENCE_CREATE, dataToPost,
        auth, (data, status) => {
          setLoadingSubmit(false);
          console.log("data", data, "status", status)
          let msg = data.text ? JSON.parse(data.text).message : data.message;
          openSnackbar(msg)
          if (status === "Done") {
            //console.log('data', data);
            if (data.success === true) {
              setForceUpdateRecurrences(true);
              setForceUpdateCalendar(true);
              closeModal();
              history.push('/account/mes-trajets');
            }
          } else if (status === "Error") {
            console.error("Error de requête")
          }
        });
    }
  }

  const handleChangeStep = async ({activeStep}) => {
    const res = await trigger();
    setCurrentStep(activeStep);
  }

  const handleSelectRzpPoint = (data) => {
    //setPointDepart(data);
    setDeparture(data);
    setDepartureType(1);
    setValue("start_position", data.nom);
  };

  return (
    <>
      <div className="modal-header text-white border-0 position-relative" style={{
        backgroundImage: "url(/assets/svg/modal-header.svg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: 100
      }}>

        {loadingSubmit && <Spinner/>}

        <p className="mx-auto mb-0 mt-2">
          Créer mon trajet ({currentStepState}/3)</p>
        <button className="btn position-absolute" style={{top: 10, right: 10}} data-bs-dismiss="modal">
          <svg width="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.81 51.61"><title>Close Button</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Calque_1" data-name="Calque 1">
                <path fill="#fff"
                      d="M24.37,0C2.66-.69-5.62,24.67,3.91,41a21.63,21.63,0,0,0,24.51,9.81,20.14,20.14,0,0,0,5-2.19,40.81,40.81,0,0,0,10-7.11c6.53-6.26,9.26-16,6.06-24.56C45.49,6.38,35.42.37,24.37,0Z"/>
                <path fill="#a0cf67"
                      d="M27.11,24.74c2.93,3.12,6.69,7,5.15,8.19-1.2,1-4.55-1.64-7.23-4.7-2.91-3.33-7.33-7.39-6.47-9.58C20.93,18.08,24.14,21.57,27.11,24.74Z"/>
                <path fill="#a0cf67"
                      d="M24.34,24.1c3.12-2.93,7-6.69,8.2-5.15,1,1.2-1.65,4.54-4.71,7.23-3.33,2.91-7.39,7.32-9.58,6.46C17.67,30.28,21.16,27.06,24.34,24.1Z"/>
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div className="wizard mt-3 container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <StepWizard
            key={wizardID}
            onStepChange={handleChangeStep}
            /*isHashEnabled*/
            transitions={wizard.transitions}
            instance={setInstance}
            initialStep={1}
          >
            <First step={1} isActive={currentStepState === 1} errors={errors} form={register} values={getValues}
                   google={google} fromRzp={departure} fromRzpName={departure?.nom}
                   onSelectRzpPoint={(data) => handleSelectRzpPoint(data)}
                   onChangePointDepart={(data) => setPointDepart(data)}
                   onChangePointDestination={(data) => setPointDestination(data)}/>
            <Second step={2} isActive={currentStepState === 2} errors={errors} form={register} values={getValues}/>
            <Last step={3} isActive={currentStepState === 3} errors={errors} form={register} values={getValues} subscription={auth.otherDetails ? auth.otherDetails.subscription : null}/>
          </StepWizard>
        </form>
      </div>
    </>
  );
};

export default withRouter(CreateRoute);
