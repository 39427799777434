import React from 'react';
import ReactInputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';

const Time = React.forwardRef(({ id, label, ...props }, ref) => {
    const ID = React.useMemo(() => id || uuidv4(), [id]);

    return (
        <div className="py-2 pb-4">
            {
                label &&
                <label className="pb-3" htmlFor={ID}>{label}</label>
            }
            <ReactInputMask type="time" {...props} className={"form-control" + (props.className ? ` ${props.className}` : "")} id={ID} inputRef={ref} min={props.min} />
            {
                (props.errors && props.errors[props.name]) &&
                <p className="text-danger">Obligatoire</p>
            }
        </div>
    );
});

export default Time;
