import React, {useEffect, useState, createRef, useLayoutEffect} from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { GOOGLE_MAPS_API } from '../../../constant';
import RzpPointIcon from '../../../assets/images/iconPointRzp.svg';

const mapStyles = {
    position: "relative",
    width: "100%",
    height: 400
}

const GoogleMap = ({markers, rzpMarkers, google, initialCenter, onMapBounds, onSelectRzpPoint}) => {
    const ref = createRef();

    const [zoom, setZoom] = useState(5);
    const [center, setCenter] = useState(initialCenter);

    const [latitudeNE, setLatitudeNE] = useState(0);
    const [longitudeNE, setLongitudeNE] = useState(0);
    const [latitudeSO, setLatitudeSO] = useState(0);
    const [longitudeSO, setLongitudeSO] = useState(0);

    useEffect(() => {
        if (markers.length > 0) {
            const bounds = new google.maps.LatLngBounds();

            var needBounds = false;
            var countMarkers = 0;

            markers.forEach(marker => {
                if (marker.lat !== undefined) {
                    bounds.extend(marker);
                    needBounds = true;
                    countMarkers++;
                }
            });

            if (needBounds) {
                if (countMarkers == 1) {
                    bounds.extend({lat: markers[0].lat + 0.005, lng: markers[0].lng + 0.005});
                    bounds.extend({lat: markers[0].lat - 0.005, lng: markers[0].lng - 0.005});
                }
                ref.current?.map.fitBounds(bounds, 8);
            }
        }
    }, [markers]);

    const setBounds = () => {
        const bounds = ref.current?.map?.getBounds();
        if (bounds) {
            let latitudeNE = 0;
            let longitudeNE = 0;
            let latitudeSO = 0;
            let longitudeSO = 0;
            if (bounds.getNorthEast()) {
                latitudeNE = bounds.getNorthEast().lat();
                longitudeNE = bounds.getNorthEast().lng();
            }
            if (bounds.getSouthWest()) {
                latitudeSO = bounds.getSouthWest().lat();
                longitudeSO = bounds.getSouthWest().lng();
            }

            setLatitudeNE(latitudeNE);
            setLongitudeNE(longitudeNE);
            setLatitudeSO(latitudeSO);
            setLongitudeSO(longitudeSO);

            onMapBounds(latitudeNE, longitudeNE, latitudeSO, longitudeSO);
        }
    }

    return (
        <Map
            ref={ref}
            google={google}
            zoom={zoom}
            containerStyle={mapStyles}
            initialCenter={center}
            onReady={() => {
                setBounds();
            }}
            onBoundsChanged={() => {
                setBounds();
            }}
        >
            {
                markers?.map((el, index) => <Marker position={el} key={index} />)
            }
            {
                rzpMarkers?.map(el => {
                    return (
                        <Marker
                            key={el.id}
                            position={{
                                lat: el.latitude,
                                lng: el.longitude,
                            }}
                            icon={RzpPointIcon}
                            item={el}
                            onClick={(props, marker, event) => {
                                onSelectRzpPoint(marker.item)
                            }}
                        />
                    );
                })
            }
        </Map>
    );
};

export default GoogleMap;
