import React, {useState, useRef, useLayoutEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link
} from "react-router-dom";

import * as bootstrap from "bootstrap";
import {v4 as uuidv4} from 'uuid';

import TripsAroundMe from "./pages/TripsAroundMe";

import Header from "./components/Header";
import Navigation from "./components/Navigation";
import CreateRoute from "./pages/CreateRoute/CreateRoute";
import BsModal from "./components/Modals/Modal";
import MyTrips from "./pages/MyTrips";
import Dashboard from "./pages/Dashboard";
import PrivateRoutes from "./utils/private-routes";
import useContextApp from "./hooks/useContextApp";
import PlusIcon from './assets/images/moreSecondary.svg';
import EditRecurrentRoute from "./pages/EditRecurrentRoute/EditRecurrentRoute";
import ProgrammedRequestModal from "./pages/ProgrammedRequestModal/ProgrammedRequestModal";
import ProgrammedRequestAskModal from "./pages/ProgrammedRequestAskModal/ProgrammedRequestAskModal";
import HashtagTrips from "./pages/HashtagTrips";
import EditProgrammedRoute from "./pages/EditProgrammedRoute/EditProgrammedRoute";
import EditTrajetRoute from "./pages/EditTrajetRoute/EditTrajetRoute";
import ContactProfileRoute from "./pages/ContactProfileRoute/ContactProfileRoute";
import {GoogleApiWrapper} from "google-maps-react";
import {GOOGLE_MAPS_API} from "./constant";
import ShareModal from "./components/ShareModal";
import OtherProgrammedRequestAskModal from "./pages/OtherProgrammedRequestAskModal/OtherProgrammedRequestAskModal";

const AccountApp = (props) => {
  const [auth] = useContextApp();

  const [contactProfile, setContactProfile] = useState(null);
  const [trajetEdit, setTrajetEdit] = useState(null);
  const [programmedEdit, setProgrammedEdit] = useState(null);
  const [recurrenceEdit, setRecurrenceEdit] = useState(null);
  const [programmedRequest, setProgrammedRequest] = useState(null);
  const [programmedRequestAsk, setProgrammedRequestAsk] = useState(null);

  const [modalCreateRouteBs, setModalCreateRouteBs] = useState(null);
  const [modalEditRecurrentRouteBs, setModalEditRecurrentRouteBs] = useState(null);
  const [modalProfileRouteBs, setModalProfileRouteBs] = useState(null);
  const [modalEditTrajetRouteBs, setModalEditTrajetRouteBs] = useState(null);
  const [modalEditProgrammedRouteBs, setModalEditProgrammedRouteBs] = useState(null);
  const [modalProgrammedRequestBs, setModalProgrammedRequestBs] = useState(null);
  const [modalProgrammedRequestAskBs, setModalProgrammedRequestAskBs] = useState(null);
  const [modalOtherProgrammedRequestAskBs, setModalOtherProgrammedRequestAskBs] = useState(null);
  const [modalShareBs, setModalShareBs] = useState(null);

  const [createRouteCurrentStep, setCreateRouteCurrentStep] = useState(1)
  const [createRouteWizardID, setCreateRouteWizardID] = useState(uuidv4());

  useLayoutEffect(() => {
    if (modalCreateRouteBs === null) {
      const mb = document.getElementById('modalCreateRoute');
      mb.addEventListener('hidden.bs.modal', () => {
        setCreateRouteCurrentStep(1);
        setCreateRouteWizardID(uuidv4());
      });
      setModalCreateRouteBs(new bootstrap.Modal(mb));
    }
    if (modalEditRecurrentRouteBs === null) {
      setModalEditRecurrentRouteBs(new bootstrap.Modal(document.getElementById('modalEditRecurrentRoute')));
    }
    if (modalProfileRouteBs === null) {
      setModalProfileRouteBs(new bootstrap.Modal(document.getElementById('modalProfileRoute')));
    }
    if (modalEditTrajetRouteBs === null) {
      setModalEditTrajetRouteBs(new bootstrap.Modal(document.getElementById('modalEditTrajetRoute')));
    }
    if (modalEditProgrammedRouteBs === null) {
      setModalEditProgrammedRouteBs(new bootstrap.Modal(document.getElementById('modalEditProgrammedRoute')));
    }
    if (modalProgrammedRequestBs === null) {
      setModalProgrammedRequestBs(new bootstrap.Modal(document.getElementById('modalProgrammedRequest')));
    }
    if (modalProgrammedRequestAskBs === null) {
      setModalProgrammedRequestAskBs(new bootstrap.Modal(document.getElementById('modalProgrammedRequestAsk')));
    }
    if (modalOtherProgrammedRequestAskBs === null) {
      setModalOtherProgrammedRequestAskBs(new bootstrap.Modal(document.getElementById('modalOtherProgrammedRequestAsk')));
    }
    if (modalShareBs === null) {
      setModalShareBs(new bootstrap.Modal(document.getElementById('shareModal')));
    }
  });

  if (auth === undefined || auth === null || auth.token === undefined || auth.token === null || auth.loading) {

    return (
      <>
        <div>
          <p>Chargement...</p>
        </div>
      </>
    );
  }

  return (
    <>
      <ShareModal closeModal={() => {
        if (modalShareBs) {
          modalShareBs.hide();
        }
      }}/>
      <BsModal id="modalCreateRoute" size="lg">
        <CreateRoute google={props.google} currentStep={createRouteCurrentStep} wizardID={createRouteWizardID}
                     setCurrentStep={setCreateRouteCurrentStep} closeModal={() => {
          if (modalCreateRouteBs) {
            modalCreateRouteBs.hide();
          }
        }}/>
      </BsModal>
      <BsModal id="modalEditRecurrentRoute" size="lg">
        <EditRecurrentRoute recurrence={recurrenceEdit} closeModal={() => {
          if (modalEditRecurrentRouteBs) {
            modalEditRecurrentRouteBs.hide();
          }
        }}/>
      </BsModal>
      <BsModal id="modalProfileRoute" size="xl">
        <ContactProfileRoute profile={contactProfile} closeModal={() => {
          if (modalProfileRouteBs) {
            modalProfileRouteBs.hide();
          }
        }}/>
      </BsModal>
      <BsModal id="modalEditTrajetRoute" size="md">
        <EditTrajetRoute trajet={trajetEdit} closeModal={() => {
          if (modalEditTrajetRouteBs) {
            modalEditTrajetRouteBs.hide();
          }
        }} onContact={(contact) => {
          setContactProfile(contact);
          if (modalEditTrajetRouteBs) {
            modalEditTrajetRouteBs.hide();
          }
          if (modalProfileRouteBs) {
            modalProfileRouteBs.show();
          }
        }}/>
      </BsModal>
      <BsModal id="modalEditProgrammedRoute" size="lg">
        <EditProgrammedRoute programmed={programmedEdit} closeModal={() => {
          if (modalEditProgrammedRouteBs) {
            modalEditProgrammedRouteBs.hide();
          }
        }}/>
      </BsModal>
      <BsModal id="modalProgrammedRequest" size="sm">
        <ProgrammedRequestModal programmedRequest={programmedRequest} closeModal={() => {
          if (modalProgrammedRequestBs) {
            modalProgrammedRequestBs.hide();
          }
        }}/>
      </BsModal>
      <BsModal id="modalProgrammedRequestAsk" size="md">
        <ProgrammedRequestAskModal programmedRequest={programmedRequestAsk} closeModal={() => {
          if (modalProgrammedRequestAskBs) {
            modalProgrammedRequestAskBs.hide();
          }
        }}/>
      </BsModal>
      <BsModal id="modalOtherProgrammedRequestAsk" size="md">
        <OtherProgrammedRequestAskModal programmedRequest={programmedRequestAsk} closeModal={() => {
          if (modalOtherProgrammedRequestAskBs) {
            modalOtherProgrammedRequestAskBs.hide();
          }
        }}/>
      </BsModal>
      <Router>
        <Header/>
        <div className="mb-5">
          <Navigation/>
        </div>

        <Switch>
          <PrivateRoutes path="/account/hashtag/:tagId" component={HashtagTrips}/>
          <PrivateRoutes path="/account/creer-un-trajet" component={CreateRoute}/>
          <PrivateRoutes path="/account/mes-trajets"><MyTrips onEditRecurrence={(trip) => {
            setRecurrenceEdit(trip);
            if (modalEditRecurrentRouteBs) {
              modalEditRecurrentRouteBs.show();
            }
          }} onTrajet={(trajet) => {
            setTrajetEdit(trajet);
            if (modalEditTrajetRouteBs) {
              modalEditTrajetRouteBs.show();
            }
          }} onProgrammed={(programmed) => {
            setProgrammedEdit(programmed);
            if (modalEditProgrammedRouteBs) {
              modalEditProgrammedRouteBs.show();
            }
          }} onProgrammedRequest={(request) => {
            setProgrammedRequest(request);
            if (modalProgrammedRequestBs) {
              modalProgrammedRequestBs.show();
            }
          }} onProgrammedRequestAsk={(request) => {
            setProgrammedRequestAsk(request);
            if (modalProgrammedRequestAskBs) {
              modalProgrammedRequestAskBs.show();
            }
          }}
                                                              onContact={(contact) => {
                                                                setContactProfile(contact);
                                                                if (modalEditTrajetRouteBs) {
                                                                  modalEditTrajetRouteBs.hide();
                                                                }
                                                                if (modalProfileRouteBs) {
                                                                  modalProfileRouteBs.show();
                                                                }
                                                              }}/></PrivateRoutes>
          <PrivateRoutes path="/account/mon-tableau-de-bord"><Dashboard onShare={() => {
            if (modalShareBs) {
              modalShareBs.show();
            }
          }}/></PrivateRoutes>
          <PrivateRoutes path="/account"><TripsAroundMe onContact={(contact) => {
            setContactProfile(contact);
            if (modalEditTrajetRouteBs) {
              modalEditTrajetRouteBs.hide();
            }
            if (modalProfileRouteBs) {
              modalProfileRouteBs.show();
            }
          }}/></PrivateRoutes>
        </Switch>
      </Router>

      <Link to="/account/creer-un-trajet" className={"plus-menu-icon"} data-bs-toggle="modal"
            data-bs-target="#modalCreateRoute">
        <img src={PlusIcon} className={"w-100"} alt={"Créer un trajet"}/>
      </Link>

    </>
  );
}

export default GoogleApiWrapper({apiKey: GOOGLE_MAPS_API})(AccountApp);
