import React from 'react';
import { appContext, APP_REDUX_TYPES } from '../utils/context';

const useContextApp = () => {
    const {app} = React.useContext(appContext);

    const setToken = (payload) => {
        app.dispatch({type: APP_REDUX_TYPES.ADD_TOKEN, payload: payload});
    }

    const removeToken = () => {
        app.dispatch({type: APP_REDUX_TYPES.REMOVE_TOKEN});
    }

    const setUser = (payload) => {
        app.dispatch({type: APP_REDUX_TYPES.ADD_USER, payload: payload});
    }

    const removeUser = () => {
        app.dispatch({type: APP_REDUX_TYPES.REMOVE_USER});
    }

    const setForceUpdateRecurrences = (payload) => {
        app.dispatch({type: APP_REDUX_TYPES.SET_FORCE_UPDATE_RECURRENCES, payload: payload});
    }

    const setForceUpdateCalendar = (payload) => {
        app.dispatch({type: APP_REDUX_TYPES.SET_FORCE_UPDATE_CALENDAR, payload: payload});
    }

    const setOtherDetails = (payload) => {
        app.dispatch({type: APP_REDUX_TYPES.SET_OTHER_DETAILS, payload: payload});
    }
    const setTripData = (payload) => {
        app.dispatch({type: APP_REDUX_TYPES.SET_TRIP, payload: payload});
    }

    const actions = {
        setToken,
        removeToken,
        setUser,
        removeUser,
        setForceUpdateRecurrences,
        setForceUpdateCalendar,
        setOtherDetails,
        setTripData
    }

    return [app.state, actions];
};

export default useContextApp;
