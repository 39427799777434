import React from 'react';

export default function TextHighlight(props) {
    return (
        <div className="hashtag-btn mb-2" >
            {props.title}
        </div>
    );

}
