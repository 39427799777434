import React, {useEffect, useState} from 'react';

import Title from '../components/Title';
import TripCard from '../components/TripCard';

import useRequest from "../hooks/useRequest";
import useContextApp from "../hooks/useContextApp";
import {
    API_PROGRAMMED_TAG,
    API_TAG_UNSUBSCRIBE
} from "../utils/api";
import {useSnackbar} from "react-simple-snackbar";
import SearchIcon from "../assets/images/searchSecondary.svg";
import {useHistory} from "react-router-dom";

export default function HashtagTrips(props) {
    const [handleRequest] = useRequest();
    const [auth] = useContextApp();
    const [openSnackbar] = useSnackbar()

    let history = useHistory();

    const [hashtag, setHashtag] = useState(null);
    const [hashtagTrips, setHashtagTrips] = useState([]);
    const [requestedHashtagTrips, setRequestedHashtagTrips] = useState(false);
    const [requestingHashtagTrips, setRequestingHashtagTrips] = useState(false);

    const [hashtagSubscribed, setHashtagSubscribed] = useState(false);

    const params = props.match.params;

    useEffect(() => {
        if (!requestedHashtagTrips && !requestingHashtagTrips) {
            setRequestingHashtagTrips(true);
            handleRequest("get", API_PROGRAMMED_TAG + "?tagId="+params.tagId+"&latitude="+auth.user.profile?.commune?.latitude+"&longitude="+auth.user.profile?.commune?.longitude, {},
                auth, (data, status) => {
                    if (status === "Done") {
                        console.log('data', data);
                        /*if (!data.subscribed) {
                            history.push("/account");
                        }*/
                        setHashtagSubscribed(data.subscribed);
                        setHashtag(data.tag);
                        setHashtagTrips(data.programmed);
                        setRequestingHashtagTrips(false);
                        setRequestedHashtagTrips(true);
                    } else if (status === "Error") {
                        //console.error(data);
                    }
                });
        }
    }, [requestedHashtagTrips])

    const unsubscribe = () => {
        handleRequest("delete", API_TAG_UNSUBSCRIBE, {
                tagId: params.tagId
            },
            auth, (data, status) => {
                if (status === "Done") {
                    openSnackbar("Désabonné du hashtag");
                    history.push("/account");
                } else if (status === "Error") {
                    //console.error(data);
                }
            });
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    {requestedHashtagTrips && hashtag !== null &&
                    <div className="col-12">
                        <div className="mb-3 d-flex justify-content-between">
                            <Title title={"Trajets associés au #" + hashtag.name} icon={SearchIcon}/>
                            {hashtagSubscribed &&
                                <button className="btn btn-sm btn-secondary" onClick={() => {unsubscribe()}}>Me désabonner du #</button>
                            }
                        </div>
                        {requestedHashtagTrips && hashtagTrips.length > 0 &&
                        <div className="row list-of-trips mb-2">
                            {
                                hashtagTrips.map((trip, index) => {
                                    return (
                                        <div className="col-12 col-md-6 col-xl-4 mb-3" key={index}>
                                            <TripCard data={trip[0]} needRefresh={() => setRequestedHashtagTrips(false)}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        }
                        {requestedHashtagTrips && hashtagTrips.length === 0 &&
                            <p>Aucun trajet correspondant au hashtag</p>
                        }
                        {!requestedHashtagTrips && requestingHashtagTrips &&
                            <p>Chargement en cours...</p>
                        }
                    </div>
                    }
                </div>
            </div>
        </>
    );

}
