import React from 'react';

const SelectButton = React.forwardRef(({ id, title, active, handleSelect, ...props }, ref) => {
    return (
        <label className={`custom-control custom-radio mx-1 w-100 btn btn-choice btn-choice btn-${active ? "primary" : "light"} d-flex align-items-center ${props.className}`}>
            <input type="radio" name={props.name} id={id} {...props} value={props.value} defaultChecked={active} onClick={() => handleSelect(id)} className="custom-control-input btn-check" ref={ref} />
            <span className="custom-control-indicator">{title}</span>
        </label>
    );
});

export default SelectButton;