import React from 'react';
import { Redirect } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';

const Logout = () => {
    const [,{removeToken}] = useContextApp();

    React.useEffect(() => {
        removeToken();
    // eslint-disable-next-line
    }, [])

    return (
        <Redirect to="/login" />
    );
};

export default Logout;