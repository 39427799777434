import React, {useEffect, useState} from 'react';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import Tooltip from 'tooltip.js';

import Title from '../components/Title';
import TripCard from '../components/TripCard';

import CarIcon from '../assets/images/carWithThumb.svg';
import useRequest from "../hooks/useRequest";
import useContextApp from "../hooks/useContextApp";
import {API_PROGRAMMED_ME, API_CALENDAR} from "../utils/api";
import moment from "moment";
import {getTripOriginCity, getTripOriginDescription, getTripUserType} from "../utils/tools";
import {useForm} from "react-hook-form";
import {Date} from "../components/form/datetime";
import Export from "../components/Export";

export default function MyTrips(props) {
  const [handleRequest] = useRequest();
  const [auth, {setForceUpdateCalendar, setForceUpdateRecurrences}] = useContextApp();

  const [data, setData] = useState([]);
  const [requested, setRequested] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const [events, setEvents] = useState([]);
  const [requestedEvents, setRequestedEvents] = useState(false);
  const [requestingEvents, setRequestingEvents] = useState(false);

  useEffect(() => {
    if (!requested && !requesting) {
      setRequesting(true);
      console.log("URL", API_PROGRAMMED_ME);
      handleRequest("get", API_PROGRAMMED_ME, {},
        auth, (data, status) => {
          if (status === "Done") {
            console.log('data', data);
            setData(data);
            setRequesting(false);
            setRequested(true);
          } else if (status === "Error") {
            //console.error(data);
          }
        });
    }
  }, [requested])

  useEffect(() => {
    if (!requestedEvents && !requestingEvents) {
      setRequestingEvents(true);
      console.log("URL", API_CALENDAR);
      handleRequest("get", API_CALENDAR, {},
        auth, (data, status) => {
          if (status === "Done") {
            console.log('data', data);
            setEvents(data);
            setRequestingEvents(false);
            setRequestedEvents(true);
          } else if (status === "Error") {
            //console.error(data);
          }
        });
    }
  }, [requestedEvents])

  useEffect(() => {
    if (auth.forceUpdateRecurrences === true) {
      setForceUpdateRecurrences(false);
      setRequested(false);
    }
    if (auth.forceUpdateCalendar === true) {
      setForceUpdateCalendar(false);
      setRequestedEvents(false);
    }
  }, [auth])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <Title title="Mes trajets réguliers" icon={CarIcon}/>
            </div>
            <div>
              {requested && data.recurrences.length > 0 &&
              <div className="row mb-2">
                {
                  data.recurrences.map((trip, index) => {
                    return (
                      <div className="col-12 col-md-6 col-xl-4 mb-3" key={index}>
                        <TripCard data={trip} isRecurrent={true} needRefresh={() => setRequested(false)}
                                  onEditRecurrence={(trip) => props.onEditRecurrence(trip)}
                                  onContact={(user) => props.onContact(user)}/>
                      </div>
                    )
                  })
                }
              </div>
              }
              {requested && data.recurrences.length === 0 &&
              <p>Aucun trajet régulier</p>
              }
              {!requested && requesting &&
              <p>Chargement en cours...</p>
              }
          </div>
          </div>
          <div className="col-12 mt-4">
            <div className="mb-3">
              <Title title="Mes trajets réalisés et programmés" icon={CarIcon}/>
            </div>
            <div className={"pb-5"}>
              <div className={"mb-3"}>
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  locale={"fr"}
                  firstDay={1}
                  headerToolbar={{
                    left: "prev",
                    center: "title",
                    right: "dayGridWeek dayGridMonth next"
                  }}
                  titleFormat={
                    {month: 'long'}
                  }
                  buttonText={{
                    today: 'Aujourd\'hui',
                    month: 'Mois',
                    week: 'Semaine',
                    day: 'Jour',
                    list: 'Liste'
                  }}
                  eventClick={(info) => {
                    const eventType = info.event.id.substring(0, 4);
                    const eventData = info.event.extendedProps.data;
                    switch (eventType) {
                      case "trj_":
                        props.onTrajet(eventData);
                        break;
                      case "rqt_":
                        if (eventData.user.id === auth.user.id) {
                          props.onProgrammedRequest(eventData);
                        } else {
                          props.onProgrammedRequestAsk(eventData);
                        }
                        break;
                      case "prg_":
                        props.onProgrammed(eventData);
                        break;
                      default:
                        break;
                    }
                  }}
                  eventDidMount={(info) => {
                    //console.log("info", info.event.extendedProps.data);
                    //info.el.find('div.fc-title').html(info.el.find('div.fc-title').text());

                    const eventData = info.event.extendedProps.data;
                    let title = "Aucune donnée";

                    //console.log("event.data", eventData);

                    const eventType = info.event.id.substring(0, 4);
                    let startAt = null;
                    switch (eventType) {
                      case "trj_":
                        startAt = moment(eventData.startAt);
                        title = "Départ à " + startAt.format("HH:mm") + "<br/><b>" + getTripOriginCity(eventData.offer.programmed) + "</b> - " + getTripOriginDescription(eventData.offer.programmed) + "<br/>Je suis : " + ((getTripUserType(eventData.offer.programmed, auth.user.id) === "driver") ? "Conducteur" : "Passager");
                        break;
                      case "rqt_":
                        startAt = moment(eventData.programmed.startAt);
                        title = "Départ à " + startAt.format("HH:mm") + "<br/><b>" + getTripOriginCity(eventData.programmed) + "</b> - " + getTripOriginDescription(eventData.programmed) + "<br/>Je suis : " + ((getTripUserType(eventData.programmed, auth.user.id) === "driver") ? "Conducteur" : "Passager");
                        break;
                      case "prg_":
                        startAt = moment(eventData.startAt);
                        title = "Départ à " + startAt.format("HH:mm") + "<br/><b>" + getTripOriginCity(eventData) + "</b> - " + getTripOriginDescription(eventData) + "<br/>Je suis : " + ((getTripUserType(eventData, auth.user.id) === "driver") ? "Conducteur" : "Passager");
                        break;
                      default:
                        break;
                    }

                    var tooltip = new Tooltip(info.el, {
                      title: title,
                      html: true,
                      placement: 'top',
                      trigger: 'hover',
                      container: 'body'
                    });
                  }}
                  events={events}
                />
              </div>
              <Title title={"Nombre total de trajets : " + auth.otherDetails.nbTrajets}/>
            </div>
          </div>

          <Export/>

        </div>
      </div>

    </>
  );

}
