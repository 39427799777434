import React from 'react';
import {Button} from "../../components/form/button";
import useRequest from "../../hooks/useRequest";
import useContextApp from "../../hooks/useContextApp";
import {useSnackbar} from "react-simple-snackbar";
import TripCardModale from "../../components/TripCardModale";
import {API_PROGRAMMED_TRAJET_CANCEL} from "../../utils/api";

const EditTrajetRoute = ({match, trajet, closeModal, onContact}) => {
    const [handleRequest] = useRequest();
    const [auth, {setForceUpdateCalendar}] = useContextApp();
    const [openSnackbar] = useSnackbar()

    const cancelTrajet = () => {
        handleRequest("post", API_PROGRAMMED_TRAJET_CANCEL, {
                trajetId: trajet.id
            },
            auth, (data, status) => {
                if (status === "Done") {
                    if (data.success) {
                        setForceUpdateCalendar(true);
                        closeModal();
                    }
                    openSnackbar(data.message)
                } else if (status === "Error") {
                    console.error(data);
                }
            });
    }

    const contact = () => {
        console.log("trajet", trajet);
        if (trajet.offer?.user?.id === auth.id) {
            onContact(trajet.demand?.user);
        } else {
            onContact(trajet.offer?.user);
        }
    }

    return (
        <>
            <div className="modal-header text-white border-0 position-relative" style={{
                backgroundImage: "url(/assets/svg/modal-header.svg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 100
            }}>
                <p className="mx-auto mb-0 mt-2">Mon trajet</p>
                <button className="btn position-absolute" style={{top:10, right:10}} data-bs-dismiss="modal"><svg width="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.81 51.61"><title>Close Button</title><g id="Layer_2" data-name="Layer 2"><g id="Calque_1" data-name="Calque 1"><path fill="#fff" d="M24.37,0C2.66-.69-5.62,24.67,3.91,41a21.63,21.63,0,0,0,24.51,9.81,20.14,20.14,0,0,0,5-2.19,40.81,40.81,0,0,0,10-7.11c6.53-6.26,9.26-16,6.06-24.56C45.49,6.38,35.42.37,24.37,0Z"/><path fill="#a0cf67" d="M27.11,24.74c2.93,3.12,6.69,7,5.15,8.19-1.2,1-4.55-1.64-7.23-4.7-2.91-3.33-7.33-7.39-6.47-9.58C20.93,18.08,24.14,21.57,27.11,24.74Z"/><path fill="#a0cf67" d="M24.34,24.1c3.12-2.93,7-6.69,8.2-5.15,1,1.2-1.65,4.54-4.71,7.23-3.33,2.91-7.39,7.32-9.58,6.46C17.67,30.28,21.16,27.06,24.34,24.1Z"/></g></g></svg></button>
            </div>
            <div className="container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {trajet !== null &&
                                <TripCardModale data={trajet.offer.programmed}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center py-4">
                <Button color="primary" classNames="mx-2" type="button" onClick={() => {contact()}}>Contact</Button>
                <Button color="secondary" classNames="mx-2" type="button" onClick={() => {cancelTrajet()}}>Annuler mon trajet</Button>
            </div>
        </>
    );
};

export default EditTrajetRoute;
