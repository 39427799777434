import React from 'react';

const Checkbox = React.forwardRef(({title, checked, onChange, name, value, id}, ref) => {
    const handleChange = e => {
        if (onChange)
            onChange(e.target.checked)
    }

    return (
        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input rounded-0 border-dark" name={name} id={id} checked={checked} onChange={handleChange} ref={ref} />
            {title}
          </label>
        </div>
    );
});

export default Checkbox;