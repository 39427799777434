import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Navigation(props) {

    return (
        <nav className="navbar navbar-navigation bg-white sticky-top">
            <div className="container-fluid container-lg">
                <div className="row flex-nowrap justify-content-between w-100">
                    <div className="col-auto">
                        <NavLink to="/account" exact className="nav-item" activeClassName="active">
                            Les trajets autour de moi
                        </NavLink>
                    </div>
                    <div className="col-auto">
                        <NavLink to="/account/creer-un-trajet" className="nav-item" activeClassName="active" data-bs-toggle="modal" data-bs-target="#modalCreateRoute">
                            Créer un trajet
                        </NavLink>
                    </div>
                    <div className="col-auto">
                        <NavLink to="/account/mes-trajets" className="nav-item" activeClassName="active">
                            Mes trajets
                        </NavLink>
                    </div>
                    <div className="col-auto">
                        <NavLink to="/account/mon-tableau-de-bord" className="nav-item" activeClassName="active">
                            Mon tableau de bord
                        </NavLink>
                    </div>
                </div>
            </div>
        </nav>
    );

}
