import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';

const PrivateRoutes = ({ component: Component, ...rest }) => {
    const [config] = useContextApp();

    let connected = false;

    if (config.connected === undefined || config.connected === null) {
        connected = false;
    } else {
        connected = config.connected;
    }

    if (!connected) {
        const token = localStorage.getItem("token");
        if (token) {
            connected = true;
        }
    }

    return <Route {...rest} render={(props) => (
        connected
            ?
        <Component {...props} />
            :
        <Redirect to={"/login"} />
    )} />
};

export default PrivateRoutes;
