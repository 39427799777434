import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const Input = React.forwardRef(({ id, classNames, label, ...props }, ref) => {
    const ID = React.useMemo(() => id || uuidv4(), [id]);

    return (
        <div className="form-group py-2">
            {
                label &&
                <label className="pb-3" htmlFor={ID}>{label}</label>
            }
            <input id={ID} className={`form-control ${classNames || ""}`} ref={ref} {...props} />
            {
                props.helper &&
                <small className="text-muted">{props.helper}</small>
            }
            {
                (props.errors && props.errors[props.name]) &&
                <p className="text-danger">Obligatoire</p>
            }
        </div>
    );
});

export default Input;