import React from 'react';
import { Link } from 'react-router-dom';

export default function HashtagButton(props) {

    return (
        <Link className="hashtag-btn mb-2" to={props.link}>
            #{props.title}
        </Link>
    );

}