import React, {useEffect, useState} from 'react';
import { Checkbox } from '../../../components/form/checkbox';
import SelectButton from '../../../components/form/select-button/select-button';
import { AddressInput } from '../../../components/geolocalisation/address-input';
import { GoogleMap } from '../../../components/geolocalisation/map';
import { Navigation } from '../Navigation';
import {API_GET_RZP, API_TAG_AROUND, API_TERRITORY_STATS} from "../../../utils/api";
import useContextApp from "../../../hooks/useContextApp";
import useRequest from "../../../hooks/useRequest";

const userTypes = [
    {
        id: 1,
        title: "Conducteur.rice",
        slug: "conductor"
    },
    {
        id: 2,
        title: "Passager.ère",
        slug: "passenger"
    }
]

const routeTypes = [
    {
        id: 3,
        title: "Trajet unique",
        slug: "unique"
    },
    {
        id: 4,
        title: "Trajet régulier",
        slug: "recurrent"
    }
]

const First = (props) => {
    const [auth] = useContextApp();
    const [handleRequest] = useRequest();

    const [userType, setUserType] = React.useState(userTypes[0].id)
    const [routeType, setRouteType] = React.useState(routeTypes[0].id)
    const [localisation, setLocalisation] = React.useState({});
    const [rzpFrom, setRzpFrom] = React.useState(null);

    const [requestedRzpPointsBounds, setRequestedRzpPointsBounds] = useState({
        latitudeNE: 0,
        longitudeNE: 0,
        latitudeSO: 0,
        longitudeSO: 0
    });

    const [rzpPoints, setRzpPoints] = useState([]);

    useEffect(() => {
        if (localisation.from !== undefined && localisation.from.lat !== undefined && localisation.from.lng !== undefined) {
            props.onChangePointDepart(localisation.from);
        }
        if (localisation.to !== undefined && localisation.to.lat !== undefined && localisation.to.lng !== undefined) {
            props.onChangePointDestination(localisation.to);
        }
    }, [localisation]);

    useEffect(() => {
        if (props.fromRzp !== undefined && props.fromRzp !== null && props.fromRzp.latitude !== undefined && props.fromRzp.longitude !== undefined) {
            setRzpFrom({
                lat: props.fromRzp.latitude,
                lng: props.fromRzp.longitude,
            });
        }
    }, [props.fromRzp]);

    useEffect(() => {
        if (requestedRzpPointsBounds.latitudeNE !== 0 || requestedRzpPointsBounds.longitudeNE !== 0 || requestedRzpPointsBounds.latitudeSO !== 0 || requestedRzpPointsBounds.longitudeSO !== 0) {
            handleRequest("get", API_GET_RZP + "?latitudeNE="+requestedRzpPointsBounds.latitudeNE+"&longitudeNE="+requestedRzpPointsBounds.longitudeNE+"&latitudeSO="+requestedRzpPointsBounds.latitudeSO+"&longitudeSO="+requestedRzpPointsBounds.longitudeSO, {},
                auth, (data, status) => {
                    if (status === "Done") {
                        setRzpPoints(data.points);
                    } else if (status === "Error") {
                        //console.error(data);
                    }
                });
        }
    }, [requestedRzpPointsBounds]);

    return (
        <div>
            <div className="container">
                <div className="row px-4">
                    <div className="col-12 col-md-6">
                        <AddressInput placeholder="Ma position" form={props.form} values={props.values} forceValue={props.fromRzpName} onSelect={(d) => setLocalisation(state => ({...state, from: d}))} errors={props.errors} label="Votre point de départ" name="start_position" />
                        <p>Sélectionnez un Arrêt sur le Pouce en cliquant dessus ou entrez une adresse directement dans la barre de recherche.</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <AddressInput placeholder="Destination" form={props.form} values={props.values} onSelect={(d) => setLocalisation(state => ({...state, to: d}))} errors={props.errors} label="Votre destination" name="end_position" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <label className="pb-3">Vous êtes</label>
                        <div className="d-flex">
                            {
                                userTypes?.map(el => <SelectButton key={el.id} {...el} name="userType" value={el.slug} active={el.id === userType} defaultChecked={el.id === userType} handleSelect={setUserType} ref={props.form({required: true})} />)
                            }
                        </div>
                    </div>
                    {
                        userType === userTypes[0].id &&
                        <div className="col-12 col-lg-6">
                            <label className="pb-3">Fréquence</label>
                            <div className="d-flex">
                                {
                                    routeTypes?.map(el => {
                                        //if (el.slug === "recurrent" && auth.otherDetails.subscription !== 'recurrent') return;
                                        return <SelectButton key={el.id} {...el} name="routeType" value={el.slug} active={el.id === routeType} defaultChecked={el.id === routeType} handleSelect={setRouteType} ref={props.form({required: true})} />
                                    })
                                }
                            </div>
                        </div>
                    }
                    <div className="col-12 py-3">
                        <Checkbox title="Déplacement professionnel" />
                    </div>
                    <div className="col-12">
                        <GoogleMap google={props.google} markers={[localisation.from, localisation.to, rzpFrom].filter(el => el)} rzpMarkers={rzpPoints} initialCenter={{lat: 46.227638, lng: 2.213749}} onMapBounds={(latitudeNE, longitudeNE, latitudeSO, longitudeSO) => {
                            let boundsChanged = false;
                            const newBounds = {
                                latitudeNE: requestedRzpPointsBounds.latitudeNE,
                                longitudeNE: requestedRzpPointsBounds.longitudeNE,
                                latitudeSO: requestedRzpPointsBounds.latitudeSO,
                                longitudeSO: requestedRzpPointsBounds.longitudeSO
                            }
                            if (requestedRzpPointsBounds.latitudeNE === 0 || latitudeNE > requestedRzpPointsBounds.latitudeNE) {
                                newBounds.latitudeNE = latitudeNE;
                                boundsChanged = true;
                            }
                            if (requestedRzpPointsBounds.longitudeNE === 0 || longitudeNE > requestedRzpPointsBounds.longitudeNE) {
                                newBounds.longitudeNE = longitudeNE;
                                boundsChanged = true;
                            }
                            if (requestedRzpPointsBounds.latitudeSO === 0 || latitudeSO < requestedRzpPointsBounds.latitudeSO) {
                                newBounds.latitudeSO = latitudeSO;
                                boundsChanged = true;
                            }
                            if (requestedRzpPointsBounds.longitudeSO === 0 || longitudeSO < requestedRzpPointsBounds.longitudeSO) {
                                newBounds.longitudeSO = longitudeSO;
                                boundsChanged = true;
                            }
                            if (boundsChanged) {
                                setRequestedRzpPointsBounds(newBounds);
                            }
                        }}
                        onSelectRzpPoint={(rzpPoint) => {
                            props.onSelectRzpPoint(rzpPoint);
                        }}/>
                    </div>
                </div>
            </div>
            <Navigation {...props} />
        </div>
    );
};

export default First;
