import React, {useState, useEffect} from 'react';
import {Button} from "../../components/form/button";
import {
  API_PROGRAMMED_AROUND,
  API_PROGRAMMED_ASK_ACCEPT,
  API_PROGRAMMED_ASK_REFUSE, API_SEND_MESSAGE_RDEX,
} from "../../utils/api";
import useRequest from "../../hooks/useRequest";
import useContextApp from "../../hooks/useContextApp";
import {useSnackbar} from "react-simple-snackbar";
import {capitalizeFirstLetter, getRdexDate, getRdexDestinationCity, getRdexOriginCity} from "../../utils/tools";
import moment from "moment";

const OtherProgrammedRequestAskModal = ({match, programmedRequest, closeModal}) => {
  const [openSnackbar] = useSnackbar()
  const [auth] = useContextApp();
  const [handleRequest] = useRequest();

  const [inputValue, setInputValue] = useState('' +
    'Bonjour ' + auth.trip?.trip?.passenger?.alias + ',\n\n' +
    'Votre trajet entre ' + getRdexOriginCity(auth.trip?.trip) + ' et ' + getRdexDestinationCity(auth.trip?.trip) + ' le ' + capitalizeFirstLetter(moment(getRdexDate(auth.trip?.trip)).calendar()) + ' est susceptible de m’intéresser. \n' +
    'Nous ne sommes pas inscrit·es auprès du même réseau de covoiturage, c’est pourquoi je\n' +
    'vous laisse directement mes coordonnées pour que nous puissions nous organiser.\n' +
    'Mon prénom est ' + auth.user?.prenom + '.\n' +
    'Mon numéro de téléphone est le ' + auth.user?.profile?.portable + '.\n' +
    'Mon mail est ' + auth.user?.email + '.\n\n' +
    'A très bientôt !');


  useEffect(() => {
    setInputValue('' +
      'Bonjour ' + auth.trip?.trip?.passenger?.alias + ',\n\n' +
      'Votre trajet entre ' + getRdexOriginCity(auth.trip?.trip) + ' et ' + getRdexDestinationCity(auth.trip?.trip) + ' le ' + capitalizeFirstLetter(moment(getRdexDate(auth.trip?.trip)).calendar()) + ' est susceptible de m’intéresser. \n' +
      'Nous ne sommes pas inscrit·es auprès du même réseau de covoiturage, c’est pourquoi je\n' +
      'vous laisse directement mes coordonnées pour que nous puissions nous organiser.\n' +
      'Mon prénom est ' + auth.user?.prenom + '.\n' +
      'Mon numéro de téléphone est le ' + auth.user?.profile?.portable + '.\n' +
      'Mon mail est ' + auth.user?.email + '.\n\n' +
      'A très bientôt !');
  }, auth.trip)

  const sendRequest = () => {

    const postData = {
      details: inputValue,
      journeys: {
        uuid: auth.trip.trip.uuid
      },
      driver: {state: 'recipient', uuid: auth.trip.trip.driver.uuid},
      passenger: {state: 'sender'},
      operator: auth.trip.operator.id
    }

    handleRequest("post", API_SEND_MESSAGE_RDEX, postData,
      auth, (data, status) => {
        if (status === "Done") {
          closeModal();
          openSnackbar('Demande envoyée');
        } else if (status === "Error") {
          console.error('data', data);
          closeModal();
          openSnackbar('Une erreur est survenue');
        }
      });
  }

  return (
    <>
      <div className="modal-header text-white border-0 position-relative" style={{
        backgroundImage: "url(/assets/svg/modal-header.svg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: 100
      }}>
        <p className="mx-auto mb-0 mt-2" style={{color: '#e81478', fontWeight: 'bold'}}>Cette annonce provient du site
          partenaire {auth.trip?.operator?.name}.</p>
        <button className="btn position-absolute" style={{top: 0, right: -20}} data-bs-dismiss="modal">
          <svg width="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.81 51.61"><title>Close Button</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Calque_1" data-name="Calque 1">
                <path fill="#fff"
                      d="M24.37,0C2.66-.69-5.62,24.67,3.91,41a21.63,21.63,0,0,0,24.51,9.81,20.14,20.14,0,0,0,5-2.19,40.81,40.81,0,0,0,10-7.11c6.53-6.26,9.26-16,6.06-24.56C45.49,6.38,35.42.37,24.37,0Z"/>
                <path fill="#a0cf67"
                      d="M27.11,24.74c2.93,3.12,6.69,7,5.15,8.19-1.2,1-4.55-1.64-7.23-4.7-2.91-3.33-7.33-7.39-6.47-9.58C20.93,18.08,24.14,21.57,27.11,24.74Z"/>
                <path fill="#a0cf67"
                      d="M24.34,24.1c3.12-2.93,7-6.69,8.2-5.15,1,1.2-1.65,4.54-4.71,7.23-3.33,2.91-7.39,7.32-9.58,6.46C17.67,30.28,21.16,27.06,24.34,24.1Z"/>
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div className="mt-3 container">
        <div className="container">
          <div className="row px-4">
            <div className="col-12">
              <p>Merci de compléter ou modifier le message ci-dessous. Pensez à
                laisser au moins un moyen de contact afin que le·la covoitureur·se
                du site partenaire puisse vous contacter</p>
            </div>
          </div>
          <div className="row px-4">
            <div className="col-12">
              <textarea name="msg" id="msg" style={{width: "100%", padding: 22}} rows="14" value={inputValue}>
              </textarea>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center py-4">
        <Button color="secondary" classNames="mx-2" type="button" onClick={closeModal}>Annuler</Button>
        <Button color="primary" classNames="mx-2" type="button" onClick={() => sendRequest()}>Envoyer</Button>
      </div>
    </>
  );
};

export default OtherProgrammedRequestAskModal;
