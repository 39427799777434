export const getTripOriginCity = (trip) => {
    //console.log("trip", trip);
    if (trip.pointDeparture) {
        return trip.pointDeparture?.commune?.nom;
    }
    if (trip.departure !== null) {
        return trip.departure?.nom;
    }
    return 'Adresse inconnue';
}
export const getRdexOriginCity = (trip) => {
    return trip?.from?.city ?? 'Adresse inconnue';
}

export const getTripOriginDescription = (trip) => {
    if (trip.pointDeparture === null) {
        if (trip.departureAddress === null) {
            return '';
        }
        return trip.departureAddress;
    }
    return trip.pointDeparture?.nom;
}
export const getRdexOriginDescription = (trip) => {
    return trip?.from?.address ? trip?.from?.address : trip?.from?.city + ', ' + trip?.from?.country;
}

export const getTripDestinationCity = (trip) => {
    //console.log("trip", trip);
    if (trip.point) {
        return trip.point?.commune?.nom;
    }
    if (trip.destination !== null) {
        return trip.destination?.nom;
    }
    return 'Adresse inconnue';
}

export const getRdexDestinationCity = (trip) => {
    if (trip?.to) {
        return trip?.to?.city;
    }
    return 'Adresse inconnue';
}

export const getTripDestinationDescription = (trip) => {
    if (trip.point === null) {
        if (trip.address === null) {
            return '';
        }
        return trip.address;
    }
    return trip.point.nom;
}
export const getRdexDestinationDescription = (trip) => {
    return trip?.to?.address ? trip?.to?.address : trip?.to?.city + ', ' + trip?.to?.country;
}

export const getRdexDate = (trip) => {
    if (trip) {
        for (const [key, value] of Object.entries(trip.outward)) {
            if (value !== null && value.mintime) {
                return trip?.outward?.mindate + ' ' + value.mintime;
            }
        }
    }
    return trip?.outward?.minDate;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTripUserType = (trip, userId) => {
    if (trip.offer) {
        if (trip.user?.id === userId) {
            return "driver";
        } else {
            return "passenger";
        }
    } else {
        if (trip.user?.id === userId) {
            return "passenger";
        } else {
            return "driver";
        }
    }
}

export function getProfileLevel(tripsNumber) {
    if (tripsNumber < 30) { return 'Espoir Local'; }
    if (tripsNumber > 79) { return 'Expert.e du Quotidien'; }
    return 'Talent Solidaire';
}
