import React from 'react';
import useRequest from '../hooks/useRequest';
import {MY_ACCOUNT} from './api';

const state = {
    headers: {
        'Content-type': 'application/json',
    },
    token: null,
    user: null,
    connected: false,
    loading: true,
    forceUpdateRecurrences: false,
    forceUpdateCalendar: false,
    otherDetails: {},
    trip: {} // il s'agit du trajet sur lequel on clique pour la modale (trajet rdex)
}

export const appContext = React.createContext(state);

export const APP_REDUX_TYPES = {
    ADD_TOKEN: "ADD_LOGIN_TOKEN",
    REMOVE_TOKEN: "REMOVE_LOGIN_TOKEN",
    ADD_USER: "ADD_USER_DATA",
    SET_TRIP: "SET_TRIP_DATA",
    REMOVE_USER: "REMOVE_USER_DATA",
    SET_FORCE_UPDATE_RECURRENCES: "SET_FORCE_UPDATE_RECURRENCES",
    SET_FORCE_UPDATE_CALENDAR: "SET_FORCE_UPDATE_CALENDAR",
    SET_OTHER_DETAILS: "SET_OTHER_DETAILS",
}

const apiData = {
    headers: {
        'Content-type': 'application/json',
    },
};

function reducer(state, action) {
    switch (action.type) {
        case APP_REDUX_TYPES.ADD_TOKEN:
            if (localStorage.getItem('token') !== action.payload) {
                localStorage.setItem('token', action.payload);
            }
            if (!action.payload)
                return state;
            return {
                ...state,
                headers: {
                    ...state.headers,
                    'Authorization': "Bearer " + action.payload,
                },
                token: action.payload,
                connected: true,
                loading: state.user === null,
            };
        case APP_REDUX_TYPES.REMOVE_TOKEN:
            localStorage.removeItem('token');
            return {
                ...state,
                ...apiData,
                user: null,
                connected: false
            };
        case APP_REDUX_TYPES.ADD_USER:
            localStorage.setItem('user', JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload,
                loading: state.token === null,
            }
        case APP_REDUX_TYPES.REMOVE_USER:
            localStorage.removeItem('user');
            localStorage.removeItem('otherDetails');
            return {
                ...state,
                user: undefined
            }
        case APP_REDUX_TYPES.SET_FORCE_UPDATE_RECURRENCES:
            return {
                ...state,
                forceUpdateRecurrences: action.payload
            }
        case APP_REDUX_TYPES.SET_FORCE_UPDATE_CALENDAR:
            return {
                ...state,
                forceUpdateCalendar: action.payload
            }
        case APP_REDUX_TYPES.SET_OTHER_DETAILS:
            localStorage.setItem('otherDetails', JSON.stringify(action.payload));
            return {
                ...state,
                otherDetails: action.payload
            }
        case APP_REDUX_TYPES.SET_TRIP:
            localStorage.setItem('trip', JSON.stringify(action.payload));
            return {
                ...state,
                trip: action.payload
            }
        default:
            return state;
    }
}

export const AppProvider = (props) => {
    const [handleRequest] = useRequest();
    const [state, dispatch] = React.useReducer(reducer, apiData)

    React.useEffect(() => {
        const token = localStorage.getItem("token");
        const user = JSON.parse(localStorage.getItem("user"));
        const otherDetails = JSON.parse(localStorage.getItem("otherDetails"));
        const trip = JSON.parse(localStorage.getItem("trip"));

        dispatch({type: APP_REDUX_TYPES.ADD_TOKEN, payload: token});
        dispatch({type: APP_REDUX_TYPES.ADD_USER, payload: user});
        dispatch({type: APP_REDUX_TYPES.SET_OTHER_DETAILS, payload: otherDetails});
        dispatch({type: APP_REDUX_TYPES.SET_TRIP, payload: trip});

        if (token) {
            handleRequest("get", MY_ACCOUNT, {}, {headers: {Authorization: `Bearer ${token}`}}, (data, status) => {
                if (status === "Done") {
                    dispatch({type: APP_REDUX_TYPES.ADD_USER, payload: data.user});
                    dispatch({type: APP_REDUX_TYPES.SET_OTHER_DETAILS, payload: {
                        firstConnexion: data.firstConnexion,
                        firstConnexionDate: data.firstConnexionDate,
                        nbTrajets: data.nbTrajets,
                        co2: data.co2,
                        nbSponsorChild: data.nbSponsorChild,
                    }});
                } else if (status === "Error") {
                    dispatch({type: APP_REDUX_TYPES.REMOVE_TOKEN})
                    console.error(data)
                }
            });
        }
        // eslint-disable-next-line
    }, [])

    return <appContext.Provider value={{
        app: {
            state,
            dispatch
        }
    }}>
        {props.children}
    </appContext.Provider>
}
