import React, {useEffect, useState, useLayoutEffect} from 'react';

import Title from '../components/Title';
import HashtagButton from '../components/HashtagButton';
import TripCard from '../components/TripCard';
import * as bootstrap from "bootstrap";

import SearchIcon from '../assets/images/searchSecondary.svg';
import HashtagIcon from '../assets/images/hashtagSecondary.svg';
import CarIcon from '../assets/images/carWithThumb.svg';
import useRequest from "../hooks/useRequest";
import useContextApp from "../hooks/useContextApp";
import {
  API_PROGRAMMED_AROUND, API_PROGRAMMED_AROUND_OTHER,
  API_PROGRAMMED_USERTAGS, API_RDEX_JOURNEYS,
  API_TAG_AROUND,
  API_TAG_CREATE_OR_SUBSCRIBE
} from "../utils/api";
import {useSnackbar} from "react-simple-snackbar";
import ReactTooltip from "react-tooltip";
import TripCardOther from "../components/TripCardOther";

export default function TripsAroundMe(props) {
  const [openSnackbar] = useSnackbar()
  const [handleRequest] = useRequest();
  const [auth, {setTripData}] = useContextApp();

  const [tagsAround, setTagsAround] = useState([]);
  const [requestedTagsAround, setRequestedTagsAround] = useState(false);
  const [requestingTagsAround, setRequestingTagsAround] = useState(false);

  const [programmedsAround, setProgrammedsAround] = useState([]);
  const [requestedAround, setRequestedAround] = useState(false);
  const [requestingAround, setRequestingAround] = useState(false);

  const [hashtagTrips, setHashtagTrips] = useState([]);
  const [requestedHashtagTrips, setRequestedHashtagTrips] = useState(false);
  const [requestingHashtagTrips, setRequestingHashtagTrips] = useState(false);

  // nouveau : autres covoiturages
  const [canLoadOtherProgrammed, setCanLoadOtherProgrammed] = useState(false);
  const [idsAround, setIdsAround] = useState([]);
  const [otherProgrammed, setOtherProgrammed] = useState([]);
  const [requestedOther, setRequestedOther] = useState(false);
  const [requestingOther, setRequestingOther] = useState(false);

  const [modalOtherProgrammedRequestAskBs, setModalOtherProgrammedRequestAskBs] = useState(null);

  const [newHashtag, setNewHashtag] = useState("");

  useEffect(() => {
    if (!requestedTagsAround && !requestingTagsAround && auth.user !== null) {
      setRequestingTagsAround(true);
      handleRequest("get", API_TAG_AROUND + "?latitude=" + auth.user.profile?.commune?.latitude + "&longitude=" + auth.user.profile?.commune?.longitude, {},
        auth, (data, status) => {
          if (status === "Done") {
            setTagsAround(data);
            setRequestingTagsAround(false);
            setRequestedTagsAround(true);
          } else if (status === "Error") {
            console.error(data);
          }
        });
    }
  }, [requestedTagsAround, auth])

  useEffect(() => {
    if (!requestedAround && !requestingAround && auth.user !== null) {
      setRequestingAround(true);
      handleRequest("get", API_PROGRAMMED_AROUND + "?latitude=" + auth.user.profile?.commune?.latitude + "&longitude=" + auth.user.profile?.commune?.longitude, {},
        auth, (data, status) => {
          if (status === "Done") {
            setProgrammedsAround(data.around);
            setRequestingAround(false);
            setRequestedAround(true);
          } else if (status === "Error") {
            console.error('data', data);
          }
        });
    }
  }, [requestedAround, auth])

  useEffect(() => {
    //construction d'un tableau d'id
    let tmp = [];
    if (programmedsAround.length > 0) {
      programmedsAround.forEach(e => {
        tmp.push(e[0].id)
      })
      setCanLoadOtherProgrammed(true)
      setIdsAround(tmp)
    }
  }, [programmedsAround])

  useEffect(() => {
    if (canLoadOtherProgrammed) {
      if (!requestedOther && !requestingOther && auth.user !== null) {
        setRequestingOther(true);
        handleRequest("get", API_PROGRAMMED_AROUND_OTHER + `?ids=${idsAround}`, {},
          auth, (data, status) => {
            if (status === "Done") {
              setOtherProgrammed(data.data);
              setRequestingOther(false);
              setRequestedOther(true);
              setCanLoadOtherProgrammed(false)
            } else if (status === "Error") {
              console.error('data', data);
              setCanLoadOtherProgrammed(false)
            }
          });
      }
    }
  }, [canLoadOtherProgrammed, idsAround, requestedOther, auth])

  useEffect(() => {
    if (!requestedHashtagTrips && !requestingHashtagTrips) {
      setRequestingHashtagTrips(true);
      handleRequest("get", API_PROGRAMMED_USERTAGS, {},
        auth, (data, status) => {
          if (status === "Done") {
            setHashtagTrips(data);
            setRequestingHashtagTrips(false);
            setRequestedHashtagTrips(true);
          } else if (status === "Error") {
            //console.error(data);
          }
        });
    }
  }, [requestedHashtagTrips])

  const askOther = (trip, operator) => {
    setTripData({trip, operator});
  }

  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="mb-3">
              <Title title="Trajets autour de moi" icon={SearchIcon}/>
            </div>
            {requestedAround && programmedsAround.length > 0 &&
            <div className="row list-of-trips mb-2">
              {
                programmedsAround.map((programmed, index) => {
                  return (
                    <div className="col-12 col-md-6 col-xl-4 mb-3" key={index + 'around'}>
                      <TripCard data={programmed[0]} onContact={(user) => props.onContact(user)}/>
                    </div>
                  )
                })
              }
            </div>
            }
            {requestedAround && programmedsAround.length === 0 &&
            <p>Aucun trajet autour de vous</p>
            }
            {!requestedAround && requestingAround &&
            <p>Chargement en cours...</p>
            }
            <div>
              <button type="button" className="mt-2 btn btn-primary" data-tip data-for="newTrajetButtonTooltip"
                      data-bs-toggle="modal" data-bs-target="#modalCreateRoute">Créer un trajet
              </button>
              <ReactTooltip id="newTrajetButtonTooltip" place="top" type="dark" effect="solid">
                <span>Si vous ne trouvez pas de trajet qui vous corresponde, créez le vôtre !</span>
              </ReactTooltip>
            </div>
            <div className="mt-5 mb-3">
              <Title title="Trajets associés à mes hashtags" icon={SearchIcon}/>
            </div>
            <div>
              {
                auth.otherDetails.subscription === null ?
                  (
                    <div>
                      <p>Le territoire auquel votre commune est rattachée n'a pas souscrit à cette fonctionnalité.</p>
                    </div>
                  )
                  :
                  (
                    <div>
                      {requestedHashtagTrips && hashtagTrips.length > 0 &&
                      <div className="row list-of-trips mb-2">
                        {
                          hashtagTrips.map((programmed, index) => {
                            return (
                              <div className="col-12 col-md-6 col-xl-4 mb-3" key={index + 'tags'}>
                                <TripCard data={programmed} onContact={(user) => props.onContact(user)}/>
                              </div>
                            )
                          })
                        }
                      </div>
                      }
                      {requestedHashtagTrips && hashtagTrips.length === 0 &&
                      <p>Aucun trajet associé à vos hashtags</p>
                      }
                      {!requestedHashtagTrips && requestingHashtagTrips &&
                      <p>Chargement en cours...</p>
                      }
                    </div>
                  )
              }
            </div>

            <div>
              <button type="button" className="mt-2 btn btn-primary" data-tip data-for="newTrajetButtonTooltip"
                      data-bs-toggle="modal" data-bs-target="#modalCreateRoute">Créer un trajet
              </button>
              <ReactTooltip id="newTrajetButtonTooltip" place="top" type="dark" effect="solid">
                <span>Si vous ne trouvez pas de trajet qui vous corresponde, créez le vôtre !</span>
              </ReactTooltip>
            </div>

            {/*Nouveau : trajets de plateforme externes*/}
            <div className="mb-3 mt-5">
              <Title title="Autres covoiturages" icon={SearchIcon}/>
            </div>
            {requestedOther && otherProgrammed.length > 0 &&
            <div className="row list-of-trips mb-2">
              {
                otherProgrammed.map((programmed, index) => {
                  return (
                    programmed.proposals.map(p => {
                      return (
                        <div className="col-12 col-md-6 col-xl-4 mb-3" key={index + 'other-around'}>
                          <TripCardOther operator={programmed.operator} data={p.journeys}
                                         onSend={(a, b) => askOther(a, b)}/>
                        </div>
                      )
                    })
                  )
                })
              }
            </div>
            }
            {(requestedOther && otherProgrammed.length === 0) || (programmedsAround.length === 0) &&
            <p>Aucun trajet trouvé</p>
            }
            {!requestedOther && requestingOther &&
            <p>Chargement en cours...</p>
            }
            <div>
              <button type="button" className="mt-2 btn btn-primary" data-tip data-for="newTrajetButtonTooltip"
                      data-bs-toggle="modal" data-bs-target="#modalCreateRoute">Créer un trajet
              </button>
              <ReactTooltip id="newTrajetButtonTooltip" place="top" type="dark" effect="solid">
                <span>Si vous ne trouvez pas de trajet qui vous corresponde, créez le vôtre !</span>
              </ReactTooltip>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="mb-3">
              <Title title="Mes suggestions de hashtags" icon={HashtagIcon}/>
            </div>
            <div>
              {auth.otherDetails.subscription === null ?
                (
                  <p>Le territoire auquel votre commune est rattachée n'a pas souscrit à cette fonctionnalité.</p>
                ) :
                (
                  <div>
                    <div className="mt-4 mb-2 text-center d-block">
                      {auth?.user?.profile?.commune !== undefined && auth?.user?.profile?.commune !== null &&
                      [auth.user.profile?.commune.entite !== undefined && auth.user.profile?.commune.entite !== null
                        ?
                        <p>{auth.user.profile?.commune?.entite?.nom}</p>
                        :
                        <p>{auth.user.profile?.commune?.nom}</p>
                      ]
                      }
                    </div>
                    <div>
                      {tagsAround.length > 0
                        ?
                        tagsAround.map((tag, index) => {
                          return (
                            <HashtagButton key={index + 'tag'} title={tag.name} link={"/account/hashtag/" + tag.id}/>);
                        })
                        :
                        <p>Oups, pas de suggestion. N’hésitez pas à créer votre hashtag !</p>
                      }
                    </div>
                  </div>
                )}
            </div>

            {/*<div className="mt-4 mb-2 text-center d-block">
                            <p>[ Mon entreprise ]</p>
                        </div>
                        <div>
                            <HashtagButton title="myHashtag" link="/"/>
                            <HashtagButton title="myHashtag" link="/"/>
                        </div>*/}
            <div className="mt-4 mb-3 d-flex justify-content-center">
              <Title title="Créer un hashtag"/>
            </div>
            <div>
              <div className="mb-3">
                <input type="text" className="form-control form-control-icon hashtag" placeholder="Entrer un hashtag"
                       value={newHashtag} onChange={(e) => setNewHashtag(e.target.value)}/>
              </div>
              <div className="mb-3 text-center">
                {auth.otherDetails.subscription === null && (
                  <p class="small">Le territoire auquel votre commune est rattachée n'a pas souscrit à cette
                    fonctionnalité.</p>
                )}
                <button type="button" className="btn btn-primary" disabled={auth.otherDetails.subscription === null} onClick={() => {
                  handleRequest("post", API_TAG_CREATE_OR_SUBSCRIBE, {
                      hashtag: newHashtag
                    },
                    auth, (data, status) => {
                      if (status === "Done") {
                        setNewHashtag("");
                        openSnackbar(data.message);
                      } else if (status === "Error") {
                        console.error(data);
                      }
                    });
                }}>Valider
                </button>

              </div>
            </div>
            <div className="mt-5 mb-3">
              <Title title="Et l'autostop ?" icon={CarIcon}/>
            </div>
            <div className="card">
              <div className="card-body">
                <p className="mb-0">Que vous soyez conducteur.rice ou passager.ère, Rezo Pouce c’est aussi de l’autostop
                  avec les Arrêts sur le Pouce !</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );

}
