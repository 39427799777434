import React from 'react';

import nextArrow from '../../../assets/images/next-arrow.svg';
import prevArrow from '../../../assets/images/prev-arrow.svg';
import { Button } from '../../../components/form/button';

const Navigation = ({
    nextStep,
    previousStep,
    totalSteps,
    step,
}) => {
    const handleClick = (e, c) => {
        e.stopPropagation();
        e.preventDefault();

        if (c) {
            c()
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-center py-4">
            {
                step > 1 &&
                <Button><img src={prevArrow} alt="" width="35" onClick={(e) => handleClick(e, previousStep)} /></Button>
            }
            <Button color="light" classNames="mx-3" data-bs-dismiss="modal">Annuler</Button>
            {
                step >= totalSteps &&
                <Button color="secondary" classNames="ml-3" type="submit">Valider</Button>
            }
            {
                step < totalSteps &&
                <Button><img src={nextArrow} alt="" width="35" onClick={(e) => handleClick(e, nextStep)} /></Button>
            }
        </div>
    );
};

export default Navigation;