import React, {useEffect, useState} from 'react';
import {Button} from "../../components/form/button";
import useRequest from "../../hooks/useRequest";
import useContextApp from "../../hooks/useContextApp";
import moment from "moment";
import {useSnackbar} from "react-simple-snackbar";
import TripCardModale from "../../components/TripCardModale";
import HeaderModaleProfile from "../../components/HeaderModaleProfile";
import HashtagButton from "../../components/HashtagButton";
import TextHighlight from "../../components/TextHighlight";
import {API_PROGRAMMED_ME, MY_ACCOUNT} from "../../utils/api";
import {getProfileLevel} from "../../utils/tools";
import ValidIcon from '../../assets/images/validPrimary.svg';
import TextHighlightIcon from "../../components/TextHighlightIcon";

const ContactProfileRoute = ({match, profile, closeModal}) => {
    const [handleRequest] = useRequest();
    const [auth, {setForceUpdateCalendar}] = useContextApp();
    const [openSnackbar, closeSnackbar] = useSnackbar()

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (profile !== null) {
            handleRequest("get", MY_ACCOUNT + "/" + profile.id, {},
                auth, (data, status) => {
                    if (status === "Done") {
                        console.log("data", data);
                        setUser(data);
                    } else if (status === "Error") {
                        //console.error(data);
                    }
                });
        }
    }, [profile]);

    const displayCivilite = () => {
        if (profile.profile.sexe === 'masculin') {
            return "Homme";
        } else if (profile.profile.sexe === 'feminin') {
            return "Femme";
        }

        return "Non communiqué";
    }

    console.log("profile", profile);

    return (
        <>
            <div className="modal-header text-white border-0" style={{
                backgroundImage: "url(/assets/svg/modal-header.svg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 100
            }}>
                <button className="btn ml-auto" data-bs-dismiss="modal"><svg width="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.81 51.61"><title>Close Button</title><g id="Layer_2" data-name="Layer 2"><g id="Calque_1" data-name="Calque 1"><path fill="#fff" d="M24.37,0C2.66-.69-5.62,24.67,3.91,41a21.63,21.63,0,0,0,24.51,9.81,20.14,20.14,0,0,0,5-2.19,40.81,40.81,0,0,0,10-7.11c6.53-6.26,9.26-16,6.06-24.56C45.49,6.38,35.42.37,24.37,0Z"/><path fill="#a0cf67" d="M27.11,24.74c2.93,3.12,6.69,7,5.15,8.19-1.2,1-4.55-1.64-7.23-4.7-2.91-3.33-7.33-7.39-6.47-9.58C20.93,18.08,24.14,21.57,27.11,24.74Z"/><path fill="#a0cf67" d="M24.34,24.1c3.12-2.93,7-6.69,8.2-5.15,1,1.2-1.65,4.54-4.71,7.23-3.33,2.91-7.39,7.32-9.58,6.46C17.67,30.28,21.16,27.06,24.34,24.1Z"/></g></g></svg></button>
            </div>
            <div className="my-3 container">
                <div className="container">
                    <div className="row px-4">
                        {profile !== null && user !== null &&
                            <div className="col-12 row">
                                <HeaderModaleProfile user={profile} />
                                <div className="col-4 row">
                                    <div className="col-12">
                                        <p className="text-primary" style={{fontWeight: 700}}>Informations personnelles</p>
                                        <TextHighlightIcon title={"Pièce identité vérifiée"} icon={ValidIcon}/>
                                        <TextHighlight title={getProfileLevel(user.nbTrajets)}/>
                                        <TextHighlight title={user.nbTrajets + " trajets"}/>
                                      {profile.appProfile &&
                                      <div>
                                        {profile.profile.portable !== null && profile.profile.portable !== "" &&
                                        <TextHighlight title={profile.profile.portable}/>
                                        }
                                        {profile.appProfile?.useType !== null &&
                                        <TextHighlight title={profile.appProfile?.useType.libelle}/>
                                        }
                                      </div>}
                                    </div>
                                    <div className="col-12">
                                        <p className="text-primary" style={{fontWeight: 700}}>Informations du compte</p>
                                        <TextHighlight title={displayCivilite()}/>
                                        <TextHighlight title={`Inscrit depuis le ${moment(user.user.profile.createdAt).format('DD/MM/YYYY')}`}/>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <p className="text-primary" style={{fontWeight: 700}}>Préférences</p>
                                  {profile.appProfile &&
                                  <div>
                                    {profile.appProfile?.preferenceDiscussion !== null &&
                                    <TextHighlight title={profile.appProfile?.preferenceDiscussion.libelle}/>
                                    }
                                    {profile.appProfile?.preferenceMusic !== null &&
                                    <TextHighlight title={profile.appProfile?.preferenceMusic.libelle}/>
                                    }
                                    {profile.appProfile?.preferenceSmoke !== null &&
                                    <TextHighlight title={profile.appProfile?.preferenceSmoke.libelle}/>
                                    }
                                  </div>}
                                </div>
                                <div className="col-4">
                                    <p className="text-primary" style={{fontWeight: 700}}>Voiture</p>
                                  {profile.appProfile &&
                                  <div>
                                    {profile.appProfile?.carBrand !== null && profile.appProfile?.carBrand !== "" &&
                                    <TextHighlight title={profile.appProfile?.carBrand}/>
                                    }
                                    {profile.appProfile?.carModel !== null && profile.appProfile?.carModel !== "" &&
                                    <TextHighlight title={profile.appProfile.carModel}/>
                                    }
                                    {profile.appProfile?.carColor !== null && profile.appProfile?.carColor !== "" &&
                                    <TextHighlight title={profile.appProfile?.carColor}/>
                                    }
                                  </div>
                                  }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactProfileRoute;
