import React, {useEffect} from 'react';

import useContextApp from "../hooks/useContextApp";
import Title from "./Title";
import {Date} from "./form/datetime";
import {useForm} from "react-hook-form";
import moment from "moment";
import {API_CALENDAR, API_EXPORT_TRAJETS} from "../utils/api";
import useRequest from "../hooks/useRequest";

export default function Export(props) {
    const [handleRequest] = useRequest();
    const [auth] = useContextApp();

    const {register, handleSubmit, errors, getValues, trigger, setValue} = useForm()

    useEffect(() => {
        let endAt = moment();
        setValue('endAt', endAt.format("YYYY-MM-DD"));

        let startAt = moment().subtract(1, "month");
        setValue('startAt', startAt.format("YYYY-MM-DD"));
    }, []);

    const exportTrajets = (type) => {
        fetch(API_EXPORT_TRAJETS+"?type="+type+"&dateBegin="+getValues("startAt")+"&dateEnd="+getValues("endAt"), {
            method: 'GET',
            cache: 'default',
            headers: auth.headers,
            mode: 'cors'
        })
            .then(response => {
                if (type === "rpc") {
                    if (response.status !== 200) {
                        alert("Aucune preuve de covoiturage n'est disponible pour votre compte.");
                        return;
                    }
                }
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    if (type === "pdf") {
                        a.download = 'mes_trajets.pdf';
                    } else if (type === "excel") {
                        a.download = 'mes_trajets.xlsx';
                    } else {
                        a.download = 'preuves_de_covoiturage.pdf';
                    }
                    a.click();
                });
                //window.location.href = response.url;
            }).catch((err) => {
                alert("Aucune preuve de covoiturage n'est disponible pour votre compte.");
                console.log("error", err);
            });
    }

    return(
        <div className="col-12 mt-2 pb-5">
            <div className="mb-3">
                <Title title={"Exports"}/>
            </div>
            <div className={"pb-1 row"}>
                <div className="col-12 col-md-6">
                    <Date errors={props.errors} label="À partir du" placeholder="00/00/0000" name="startAt" ref={register()} />
                </div>
                <div className="col-12 col-md-6">
                    <Date errors={props.errors} label="Jusqu'au" placeholder="00/00/0000" name="endAt" ref={register()} />
                </div>
            </div>
            <div className={"d-flex"}>
                <button type={"button"} className={"btn btn-primary my-1 me-2"} onClick={() => exportTrajets("pdf")}>PDF</button>
                <button type={"button"} className={"btn btn-primary my-1 me-2"} onClick={() => exportTrajets("excel")}>Excel</button>
                <button type={"button"} className={"btn btn-secondary my-1"} onClick={() => exportTrajets("rpc")}>Preuves de covoiturage</button>
            </div>
        </div>
    );

}
