import React, {useState, useEffect} from 'react';
import { Checkbox } from '../../../../components/form/checkbox';
import { Date, Time } from '../../../../components/form/datetime';
import SelectButton from '../../../../components/form/select-button/select-button';
import moment from "moment";

export const flexibilityOptions = [
    {
        id: 5,
        title: "Non",
        slug: "none"
    },
    {
        id: 6,
        title: "+/- 15mins",
        slug: "15"
    },
    {
        id: 7,
        title: "+/- 30mins",
        slug: "30"
    },
    {
        id: 8,
        title: "+/- 1h",
        slug: "1h"
    }
]

const Unique = (props) => {
    const [flexibility, setFlexibility] = React.useState(flexibilityOptions[1].id);
    const [back, setBack] = React.useState(false);

    const [minBackDate, setMinBackDate] = useState(moment().format("YYYY-MM-DD"));

    const currentDate = moment();
    //currentDate.subtract(1, "days");

    useEffect(() => {
        if (props.values("day_go") !== null) {
            setMinBackDate(props.values("day_go"));
        }
    });

    return (
        <div className="container">
            <div className="row px-4">
                <div className="col-12 col-md-6">
                    <Date errors={props.errors} label="Jour de départ" name="day_go" min={currentDate.format("YYYY-MM-DD")} placeholder="00/00/00" onChange={(e) => {
                        if (e.target.value !== null && e.target.value.length === 10) {
                            setMinBackDate(e.target.value);
                        }
                    }} ref={props.form()} />
                </div>
                <div className="col-12 col-md-6">
                    <Time errors={props.errors} label="Heure de départ" name="hour_go" placeholder="00:00" ref={props.form()} />
                </div>
                <div className="col-12">
                    <label className="pb-3">Flexibilité</label>
                    <div className="row">
                        {
                            flexibilityOptions?.map((el, index) => <SelectButton className="col my-1" key={el.id} {...el} name="flexibility" value={index} active={el.id === flexibility} defaultChecked={el.id === flexibility} handleSelect={setFlexibility} ref={props.form()} />)
                        }
                    </div>
                </div>
                <div className="py-3">
                    <Checkbox title="Trajet de retour" checked={back} onChange={setBack} name="back" ref={props.form()} />
                </div>
                {
                    back &&
                    <>
                        <div className="col-12 col-md-6">
                            <Date errors={props.errors} label="Jour de retour" name="day_back" placeholder="00/00/00" min={props.values("day_go")} ref={props.form()} />
                        </div>
                        <div className="col-12 col-md-6">
                            <Time errors={props.errors} label="Heure de retour" name="hour_back" placeholder="00:00" ref={props.form()} />
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Unique;
