import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';
import useRequest from '../hooks/useRequest';
import { LOGIN_USER } from '../utils/api';

import BottomLogin from '../assets/images/bottom-login.png';

function Login(props) {
    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [auth, {setToken, setUser, setOtherDetails}] = useContextApp();
    const [error, onError] = useState(null);

    const toSubmit = (values) => {
        values.username = values.username?.toLowerCase();

        handleRequest("post", 'https://api.rezopouce.fr/loginapi2', values, auth, (data, status) => {
            if (status === "Done") {
                setToken(data.token);
                //déterminons le niveau d'abonnement du territoire de la commune
                let abo = null;
                if (data.user.profile.commune?.territory) {
                    abo = 'simple';
                    if (data.user.profile.commune?.territory?.rezoproProfile?.active) {
                        abo = 'recurrent';
                    }
                }
                abo = 'recurrent';
                data.user.subscription = abo;
                setUser(data.user);
                setOtherDetails({
                    subscription: abo,
                    firstConnexion: data.firstConnexion,
                    firstConnexionDate: data.firstConnexionDate,
                    nbTrajets: data.nbTrajets,
                    co2: data.co2,
                    nbSponsorChild: data.nbSponsorChild,
                });
                props.history.push("/account")
            } else if (status === "Error") {
                switch (data.status) {
                    case 401:
                        onError("Votre adresse mail ou mot de passe est éronné.");
                        break;
                    case 400:
                        onError("Veuillez remplir tous les champs nécéssaires.");
                        break;
                    default:
                        onError(`Adresse e-mail ou mot de passe invalide (${data.status}).`);
                }
                console.error("Error on login", data);
            }
        })
    }

    return (
        <div className="login d-flex flex-column">
            <div className="row no-gutters flex-shrink-0">
                <div className="col-12 h-100">
                    <div className="d-flex align-items-center justify-content-center h-100 p-3 pb-5 mt-5">
                        <form className="col-12 col-xl-4 mt-5 card p-4" onSubmit={handleSubmit(toSubmit)}>
                            <section className="pb-3 text-center">
                                <h2 className="generic-title mb-4">Connexion</h2>
                                <hr className="generic-hr ml-0"/>
                            </section>
                            {
                                error &&
                                <div className="rounded-0 alert alert-danger" role="alert">
                                    {error}
                                </div>
                            }
                            <div className="mb-3">
                                <label htmlFor="login-mail" className="form-label">Adresse mail ou nom d'utilisateur</label>
                                <input type="text" className="form-control" id="login-mail" placeholder="Nom d'utilisateur" name="username" ref={register({required: true})} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="login-password" className="form-label">Mot de passe</label>
                                <input type="password" className="form-control" id="login-password" placeholder="••••••••••••" name="password" ref={register({required: true})} />
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                                <button type="submit" className="btn btn-primary">Connexion</button>
                                <div className="py-2">
                                    <Link to="/forgot">Mot de passe oublié ?</Link>
                                </div>
                            </div>
                            <div className="mt-3">
                                <span className="pe-2">Vous n'avez pas encore de compte ?</span><a href="https://www.rezopouce.fr/inscription-covoiturage-rezopouce">Inscrivez-vous !</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="mt-auto">
                <img src={BottomLogin} alt="footer-page-img" className="w-100" />
            </footer>
        </div>
    );
}

export default withRouter(Login);
