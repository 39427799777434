import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import {Checkbox} from "../../components/form/checkbox";
import {Date, Time} from "../../components/form/datetime";
import {Button} from "../../components/form/button";
import {baseUrlApi} from "../../utils/api";
import useRequest from "../../hooks/useRequest";
import useContextApp from "../../hooks/useContextApp";
import moment from "moment";
import {useSnackbar} from "react-simple-snackbar";

const EditRecurrentRoute = ({match, recurrence, closeModal}) => {
    const [handleRequest] = useRequest();
    const [auth, {setForceUpdateRecurrences}] = useContextApp();
    const [openSnackbar] = useSnackbar()

    const {register, handleSubmit, errors, getValues, setValue} = useForm()

    const [data, setData] = useState(null);

    useEffect(() => {
        const trip = recurrence
        if (trip !== null) {
            let startAt = null;
            if (trip['startAt']) {
                startAt = moment(trip['startAt']);
            }
            if (startAt !== null) {
                setValue('startAt', startAt.format("YYYY-MM-DD"));
            } else {
                setValue('startAt', null);
            }
            let endAt = null;
            if (trip['endAt']) {
                endAt = moment(trip['endAt']);
            }
            if (endAt !== null) {
                setValue('endAt', endAt.format("YYYY-MM-DD"));
            } else {
                setValue('endAt', null);
            }

            const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];
            days.map((day, index) => {
                if (trip[day + '0']) {
                    //Aller activé
                    setValue(day + '0', true);
                    let date0T = null;
                    if (trip[day + '0T']) {
                        date0T = moment(trip[day + '0T']);
                    }
                    if (date0T !== null) {
                        //Aller heure
                        setValue(day + '0T', date0T.format("HH:mm"));
                    } else {
                        //Aller heure null
                        setValue(day + '0T', null);
                    }
                } else {
                    //Aller désactivé
                    setValue(day + '0', false);
                }
                if (trip[day + '1']) {
                    //Retour activé
                    setValue(day + '1', true);
                    let date1T = null;
                    if (trip[day + '1T']) {
                        date1T = moment(trip[day + '1T']);
                    }
                    if (date1T !== null) {
                        //Aller heure
                        setValue(day + '1T', date1T.format("HH:mm"));
                    } else {
                        //Aller heure null
                        setValue(day + '1T', null);
                    }
                } else {
                    //Retour désactivé
                    setValue(day + '1', false);
                }
            });
        }
        setData(recurrence);
    }, [recurrence]);

    const onSubmit = (values) => {
        const dataToPost = {};
        dataToPost.startDate = values.startAt;
        dataToPost.endDate = values.endAt;
        dataToPost.trips = [];

        const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];
        days.map((day, index) => {
            const trip = {};
            if (values[day + '0']) {
                //Aller activé
                trip.haveStartTrips = true;
                trip['hourStartTrips'] = values[day + '0T'];
            } else {
                //Aller désactivé
                trip.haveStartTrips = false;
                trip['hourStartTrips'] = null;
            }
            if (values[day + '1']) {
                //Retour activé
                trip.haveBackTrips = true;
                trip['hourBackTrips'] = values[day + '1T'];
            } else {
                //Retour désactivé
                trip.haveBackTrips = false;
                trip['hourBackTrips'] = null;
            }
            dataToPost.trips.push(trip);
        });

        dataToPost.trips = JSON.stringify(dataToPost.trips);

        handleRequest("post", baseUrlApi + "/recurrence/"+recurrence.id+"/edit", dataToPost,
            auth, (data, status) => {
                if (status === "Done") {
                    setForceUpdateRecurrences(true);
                    closeModal();
                    openSnackbar(data.message)
                } else if (status === "Error") {
                    console.error(data);
                }
            });
    }

    const validateFormCheckbox = (value, checkboxName) => {
        if (getValues(checkboxName) && !getValues(checkboxName + "T")) {
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="modal-header text-white border-0 position-relative" style={{
                backgroundImage: "url(/assets/svg/modal-header.svg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 100
            }}>
                <p className="mx-auto mb-0 mt-2">Modifier mon trajet</p>
                <button className="btn position-absolute" style={{top:10, right:10}} data-bs-dismiss="modal"><svg width="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.81 51.61"><title>Close Button</title><g id="Layer_2" data-name="Layer 2"><g id="Calque_1" data-name="Calque 1"><path fill="#fff" d="M24.37,0C2.66-.69-5.62,24.67,3.91,41a21.63,21.63,0,0,0,24.51,9.81,20.14,20.14,0,0,0,5-2.19,40.81,40.81,0,0,0,10-7.11c6.53-6.26,9.26-16,6.06-24.56C45.49,6.38,35.42.37,24.37,0Z"/><path fill="#a0cf67" d="M27.11,24.74c2.93,3.12,6.69,7,5.15,8.19-1.2,1-4.55-1.64-7.23-4.7-2.91-3.33-7.33-7.39-6.47-9.58C20.93,18.08,24.14,21.57,27.11,24.74Z"/><path fill="#a0cf67" d="M24.34,24.1c3.12-2.93,7-6.69,8.2-5.15,1,1.2-1.65,4.54-4.71,7.23-3.33,2.91-7.39,7.32-9.58,6.46C17.67,30.28,21.16,27.06,24.34,24.1Z"/></g></g></svg></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-3 container">
                    <div className="container">
                        <div className="row px-4">
                            <div className="col-12 col-lg-6 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Lundi</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Aller" name="mo0" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="mo0T" ref={register({validate: (value) => validateFormCheckbox(value, "mo0")})} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Retour" name="mo1" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="mo1T" ref={register({validate: (value) => validateFormCheckbox(value, "mo1")})} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Mardi</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Aller" name="tu0" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="tu0T" ref={register({validate: (value) => validateFormCheckbox(value, "tu0")})} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Retour" name="tu1" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="tu1T" ref={register({validate: (value) => validateFormCheckbox(value, "tu1")})} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Mercredi</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Aller" name="we0" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="we0T" ref={register({validate: (value) => validateFormCheckbox(value, "we0")})} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Retour" name="we1" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="we1T" ref={register({validate: (value) => validateFormCheckbox(value, "we1")})} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Jeudi</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Aller" name="th0" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="th0T" ref={register({validate: (value) => validateFormCheckbox(value, "th0")})} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Retour" name="th1" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="th1T" ref={register({validate: (value) => validateFormCheckbox(value, "th1")})} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Vendredi</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Aller" name="fr0" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="fr0T" ref={register({validate: (value) => validateFormCheckbox(value, "fr0")})} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Retour" name="fr1" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="fr1T" ref={register({validate: (value) => validateFormCheckbox(value, "fr1")})} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Samedi</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Aller" name="sa0" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="sa0T" ref={register({validate: (value) => validateFormCheckbox(value, "sa0")})} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Retour" name="sa1" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="sa1T" ref={register({validate: (value) => validateFormCheckbox(value, "sa1")})} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Dimanche</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Aller" name="su0" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="su0T" ref={register({validate: (value) => validateFormCheckbox(value, "su0")})} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Checkbox title="Retour" name="su1" ref={register()} />
                                    <Time errors={errors} placeholder="00:00" name="su1T" ref={register({validate: (value) => validateFormCheckbox(value, "su1")})} />
                                </div>
                            </div>
                            <div className="col-12 row">
                                <div className="col-12">
                                    <p className="text-primary" style={{fontWeight: 700}}>Période</p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Date errors={errors} label="À partir du" placeholder="00/00/0000" name="startAt" ref={register({required: true })} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Date errors={errors} label="Jusqu'au" placeholder="00/00/0000" name="endAt" ref={register({required: true })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center py-4">
                    <Button color="secondary" classNames="mx-2" type="submit">Enregistrer</Button>
                </div>
            </form>
        </>
    );
};

export default EditRecurrentRoute;
