import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {Link, useHistory, withRouter} from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';
import useRequest from '../hooks/useRequest';
import { LOGIN_USER } from '../utils/api';
import {APP_REDUX_TYPES} from "../utils/context";

function LoginFromWeb(props) {
    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [auth, {setToken}] = useContextApp();
    const [error, onError] = useState(null);

    let history = useHistory();

    const locationSearch = props.location.search;

    useEffect(() => {
        const params = new URLSearchParams(locationSearch);
        const token = params.get("token");
        const redirect = params.get("redirect");

        if (token !== undefined && token !== null) {
            setToken(token)
            if (redirect !== undefined && redirect !== null) {
                history.push(redirect);
            } else {
                history.push("/account");
            }
        }
    }, [locationSearch]);

    return (
        <div className="login d-flex flex-column">
            <div className="row no-gutters flex-shrink-0">
                <div className="col-12 h-100">
                    <div className="d-flex align-items-center justify-content-center h-100 p-3 pb-5 mt-5">
                        Chargement...
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginFromWeb);
