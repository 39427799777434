import React from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";

import { HiX } from "react-icons/hi";
import useContextApp from "../hooks/useContextApp";
import {getProfileLevel} from "../utils/tools";

export default function ShareModal(props) {
    const [auth] = useContextApp();

    const shareText = () => {
        if (auth.otherDetails !== undefined && auth.otherDetails !== null) {
            return "J'ai partagé "+auth.otherDetails.nbTrajets+" trajets et économisé "+parseFloat(auth.otherDetails.co2).toFixed(2)+" Kg de CO2. Dans ma zone on fait pas mieux.\n" +
                "J'ai récemment parrainé "+auth.otherDetails.nbSponsorChild+" personnes parce que j'ai plein de potes.\n" +
                "Je suis "+getProfileLevel(auth.otherDetails.nbTrajets)+". Rejoins-moi sur rezopouce.fr !";
        }
        return "";
    }

    //const location = window.location.href;
    const location = "https://covoiturage.rezopouce.fr";

    return (
    <div className="modal fade" id="shareModal" tabIndex="-1" aria-labelledby="shareSocialModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h5 className="modal-title" id="shareSocialModal">Partager la page</h5>
                    <button type="button" className="btn btn-light px-2" onClick={() => props.closeModal()}>
                        <HiX/>
                    </button>
                </div>
                <div className="modal-body">
                    <FacebookShareButton
                        className="ms-2"
                        url={location}
                        quote={shareText()}
                    >
                        <FacebookIcon size={45} round={true} />
                    </FacebookShareButton>
                </div>
            </div>
        </div>
    </div>
    );
}
