import React, {useEffect} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import Input from '../../form/input/input';

const AddressInput = (props) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {country: ["fr"]}
        },
        debounce: 300,
    });

    useEffect(() => {
        setValue(props.forceValue, false);
    }, [props.forceValue]);

    const handleInput = (e) => {
        setValue(e.target.value);
    }

    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => results[0])
            .then((result) => {
                props.onSelect({
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng(),
                    address: result.formatted_address
                });
            })
            /*.then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                props.onSelect({lat, lng});
                console.log("📍 Coordinates: ", { lat, lng });
            })*/
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            //console.log(suggestion);
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong className="text-primary">{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    return (
        <div className="address-search-wrapper" ref={ref}>
            <Input autoComplete="off" disabled={!ready} value={value} onChange={handleInput} name={props.name} ref={props.form({required: true})} errors={props.errors} placeholder={props.placeholder} label={props.label} onSelect={props.onSelect} />
            {status === "OK" && <ul className="result-list">{renderSuggestions()}</ul>}
        </div>
    );
};

export default AddressInput;
